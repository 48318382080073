export const createMessage = async (params: {
  language: string;
  landlord_name: string;
  landlord_email: string;
  landlord_address: string;
  reason: string;
  compensation_request: string;
  tenant_name: string;
}) => {
  const response = await fetch(`templates/${params.language}-template.txt`);
  if (!response.ok) {
    console.error("Failed to fetch template");
  }
  const template = await response.text();

  let message = template;
  Object.keys(params).forEach((key) => {
    message = message.replace(new RegExp(`\\[${key}\\]`, "g"), params[key]);
  });

  return message;
};