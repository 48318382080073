import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { Account } from "../../Account";

export const Privacy = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  useAnalytics();

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: isMobile ? "5rem" : "3rem",
            fontWeight: 900,
            marginTop: isMobile ? "50%" : "25%",
          }}
        >
          {t("legal.privacy-index.title1")}
        </h1>
        <p
          style={{
            color: "#AAA6A6",
            width: isMobile ? "80%" : "50%",
            fontSize: isMobile ? "3rem" : "1rem",
            marginLeft: isMobile ? "10%" : "25%",
          }}
        >
          {t("legal.privacy-index.text1")}
        </p>
        <Notice></Notice>
      </Container>
      <Footer></Footer>
      <Account></Account>
    </div>
  );
};

const SectionTitle = ({ children }) => {
  const isMobile = useResponsiveStyles();

  return (
    <h3
      style={{
        fontSize: isMobile ? "4em" : "",
        margin: "1em 0",
        fontWeight: "bold",
      }}
    >
      {children}
    </h3>
  );
};

const Notice = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  return (
    <div
      style={{
        width: isMobile ? "80%" : "50%",
        marginLeft: isMobile ? "10%" : "25%",
        marginTop: "20%",
        marginBottom: "10%",
        fontSize: isMobile ? "2.5rem" : "1rem",
      }}
    >
      <h2>{t("legal.privacy-index.title2")}</h2>
      <p>{t("legal.privacy-index.text2")}</p>

      <SectionTitle>{t("legal.privacy-index.subtitle1")}</SectionTitle>
      <p>
        {t("legal.privacy-index.text3")}
        <br />
        {"smartmieter UG (haftungsbeschränkt)"}
        <br />
        {"Erbachstraße 13, Magstadt 71106"}
        <br />
        {"contact@smartmieter.com"}
        <br />
      </p>

      <SectionTitle>{t("legal.privacy-index.subtitle2")}</SectionTitle>
      <p>
        {t("legal.privacy-index.text4")}
        <ul>
          <li>{t("legal.privacy-index.text5")}</li>
          <li>{t("legal.privacy-index.text6")}</li>
          <li>{t("legal.privacy-index.text7")}</li>
          <li>{t("legal.privacy-index.text8")}</li>
        </ul>
      </p>

      <SectionTitle>{t("legal.privacy-index.subtitle3")}</SectionTitle>
      <p>
        {t("legal.privacy-index.text9")}
        <ul>
          <li>{t("legal.privacy-index.text10")}</li>
          <li>{t("legal.privacy-index.text11")}</li>
          <li>{t("legal.privacy-index.text12")}</li>
          <li>{t("legal.privacy-index.text13")}</li>
        </ul>
      </p>

      <SectionTitle>{t("legal.privacy-index.subtitle4")}</SectionTitle>
      <p>
        {t("legal.privacy-index.text14")}
        <ul>
          <li>{t("legal.privacy-index.text15")}</li>
          <li>{t("legal.privacy-index.text16")}</li>
          <li>{t("legal.privacy-index.text17")}</li>
        </ul>
      </p>

      <SectionTitle>{t("legal.privacy-index.subtitle5")}</SectionTitle>
      <p>{t("legal.privacy-index.text18")}</p>

      <SectionTitle>{t("legal.privacy-index.subtitle6")}</SectionTitle>
      <p>
        {t("legal.privacy-index.text19")}
        <ul>
          <li>{t("legal.privacy-index.text20")}</li>
          <li>{t("legal.privacy-index.text21")}</li>
          <li>{t("legal.privacy-index.text22")}</li>
          <li>{t("legal.privacy-index.text23")}</li>
          <li>{t("legal.privacy-index.text24")}</li>
          <li>{t("legal.privacy-index.text25")}</li>
          <li>{t("legal.privacy-index.text26")}</li>
        </ul>
        {t("legal.privacy-index.text27")}
      </p>

      <SectionTitle>{t("legal.privacy-index.subtitle7")}</SectionTitle>
      <p>{t("legal.privacy-index.text28")}</p>

      <SectionTitle>{t("legal.privacy-index.subtitle8")}</SectionTitle>
      <p>{t("legal.privacy-index.text29")}</p>

      <SectionTitle>{t("legal.privacy-index.subtitle9")}</SectionTitle>
      <p>{t("legal.privacy-index.text30")}</p>

      <p>{"Status/Stand: January 24, 2024"}</p>
    </div>
  );
};
