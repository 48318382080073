import { Header } from "../shared/header";
import {
  ContractElement,
  DarkBlurredBackground,
  CancelButton,
} from "../shared/components";
import React, { useState } from "react";
import "./vertrag.css";
import { useAuth } from "../Auth/provider";
import axios from "axios";
import { useAnalytics } from "../analytics";
import { getStageConfig } from "../config/config";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../shared/dialog";
import "../i18n";
import { useNavigate } from "react-router-dom";
import { useResponsiveStyles } from "../shared/mobile";
import { Account } from "../Account";
import { Input } from "../shared/components";

export const Contract = () => {
  const isMobile = useResponsiveStyles();
  const { t } = useTranslation();
  const [contractName, setContractName] = useState("");
  const config = getStageConfig();
  const [success, setSuccess] = useState(false);
  const [illegal, setIllegal] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [contracts, setContracts] = useState([]);
  const navigate = useNavigate();
  const { user, jwtToken } = useAuth();
  const [existingContracts, setExistingContracts] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [validContract, setValidContract] = useState(true);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const result = await axios.get(
          `${config.API_URL}/default/users/${user?.tenantId}/contracts/`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        console.log(result.data);
        const contractLabels = result.data.map((contractInfo) => ({
          info: contractInfo,
        }));
        setContracts(contractLabels);
        setExistingContracts(true);
        if (result.status === 404) {
          setExistingContracts(false);
        }
      } catch (error) {
        console.error("Error fetching contracts", error);
      }
    };

    fetchContracts();
  }, [jwtToken, config.API_URL]);

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      setFileName(file.name);
      setUploaded(true);
    } else {
      console.error("Invalid file type");
      console.log("error");
      setDialogError(true);
      setDialogMessage(t("contract-contract.pdf"));
      setFileName("");
    }
  };

  useAnalytics();

  const pollContractStatus = async (contractId) => {
    let maxAttempts = 20;
    let attempt = 0;
    const pollingInterval = 5000;

    while (attempt < maxAttempts) {
      try {
        const response = await axios.get(
          `${config.API_URL}/default/users/${user?.tenantId}/contracts/${contractId}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        console.log("Polling Response:", response);
        if (response.status === 200) {
          const { processingStatus, analysis } = response.data;
          console.log(response.data, analysis);

          if (processingStatus !== "processing") {
            setLoading(false);
            setAnalysisResult(analysis);
            setSuccess(true);
            if (analysis.status === "ILLEGAL") setIllegal(true);
            if (analysis.status === "INVALID") setValidContract(false);
            console.log("Contract processing complete:", analysis);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling contract status:", error);
        return;
      }

      attempt++;
      await new Promise((resolve) => setTimeout(resolve, pollingInterval)); // Wait before next attempt
    }

    console.error("Polling timed out.");
    setLoading(false);
    setDialogError(true);
    setDialogMessage(
      "Contract processing took too long. Please try again later."
    );
  };

  const onSubmit = async () => {
    console.log("Uploading file...");
    setUploaded(false);
    setLoading(true);
    if (!pdfFile) {
      console.error("Not a pdf file");
      console.log("error");
      setDialogError(true);
      setDialogMessage(t("contract-contract.select"));
      return;
    }
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    console.log("formData entries:");
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const result = await axios.post(
        `${config.API_URL}/default/users/${user?.tenantId}/contracts?contractName=${contractName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(`result: ${JSON.stringify(result)}`);
      if (result.status === 202) {
        console.log(result.data.analysis);
        const contractId = result.data.contractId;
        console.log(`Contract uploaded. Polling contractId: ${contractId}`);
        pollContractStatus(contractId); // Start polling
      }
    } catch (error) {
      console.log("error");
      console.log(error);
      setDialogError(true);
    }
  };

  const statusImages = {
    LEGAL: require("../shared/assets/check.png"),
    ILLEGAL: require("../shared/assets/1904654_cancel_close_cross_delete_reject_icon.png"),
    INVALID: require("../shared/assets/1904654_cancel_close_cross_delete_reject_icon.png"),
  };

  const uploadButtonStyle = {
    backgroundColor: "white",
    border: "1px solid #868080",
    borderRadius: "20px",
    width: isMobile ? "30vw" : "20vw",
    height: isMobile ? "10rem" : "auto",
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1rem",
    cursor: "pointer",
  };

  const illegalAnalysisStyle = {
    display: "grid",
    gridTemplateColumns: "6vw 24vw 10vw",
    gap: "1vw",
    flexDirection: "row",
    alignItems: "center",
    border: "2px solid red",
    marginLeft: "3%",
    width: isMobile ? "68vw" : "40vw",
    borderRadius: "20px",
    marginTop: "4rem",
    height: isMobile ? "10rem" : "7vw",
  };

  const legalAnalysisStyle = {
    display: "grid",
    gridTemplateColumns: "6vw 24vw 10vw",
    gap: "1vw",
    flexDirection: "row",
    alignItems: "center",
    border: "2px solid green",
    marginLeft: "3%",
    width: isMobile ? "68vw" : "40vw",
    borderRadius: "20px",
    marginTop: "4rem",
    height: isMobile ? "10rem" : "7vw",
  };
  const noContractAnalysisStyle = {
    display: "grid",
    gridTemplateColumns: "6vw 24vw 10vw",
    gap: "1vw",
    flexDirection: "row",
    alignItems: "center",
    border: "2px solid #FFC048",
    marginLeft: "3%",
    width: isMobile ? "68vw" : "40vw",
    borderRadius: "20px",
    marginTop: "4rem",
    height: isMobile ? "10rem" : "7vw",
  };

  const analysisImageStyle = {
    marginLeft: "1vw",
    width: isMobile ? "44px" : "3vw",
    height: isMobile ? "40px" : "3vw",
    objectFit: "scale-down",
  };

  const appealButtonStyle = {
    border: "solid",
    display: "flex",
    width: isMobile ? "8rem" : "7vw",
    height: isMobile ? "4rem" : "3.5rem",
    borderRadius: "20px",
    backgroundColor: "black",
    justifyContent: "center",
    gap: "1rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
  };

  const analyzeButtonStyle = {
    border: "solid",
    position: "absolute",
    marginLeft: isMobile ? "50%" : "25vw",
    width: isMobile ? "20vw" : "15vw",
    height: isMobile ? "6rem" : "3.5rem",
    borderRadius: "20px",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem", // Adds padding to the sides
    bottom: "0",
    textAlign: "center",
    cursor: "pointer",
  };

  const handleDismissDialog = () => {
    setDialogError(false);
  };
  const handleContractClick = (contract) => {
    console.log(contract);
    navigate(`/contract/${contract.contractName}`, { state: { contract } });
  };
  return (
    <div className="vertrag-app">
      {uploaded ? (
        <StartAnalysis
          onSubmit={onSubmit}
          contractName={contractName} // Pass contractName
          setContractName={setContractName} // Pass setter function
        >
          {fileName}
        </StartAnalysis>
      ) : (
        <Header staticColor={true} />
      )}
      <div style={{ marginTop: isMobile ? "13rem" : "5rem" }}>
        <div style={{ marginLeft: "3%" }}>
          <h1 style={{ fontSize: isMobile ? "4rem" : "" }}>
            {t("contract-contract.analyze")}
          </h1>

          <h2
            style={{
              fontSize: isMobile ? "2.5rem" : "1rem",
              color: "lightgray",
            }}
          >
            {t("contract-contract.clauses")}
          </h2>

          {loading ? (
            <div className="loading-container">
              <p>{t("contract-contract.check")}</p>
              <div className="loading-spinner"></div>
            </div>
          ) : uploaded ? null : (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                position: "relative",
                justifyContent: "space-between",
                gridTemplateColumns: "60% 40%",
              }}
            >
              <div
                style={uploadButtonStyle}
                onClick={(e) => {
                  e.stopPropagation(); // Prevents event bubbling issues
                  document.getElementById("fileUpload")?.click();
                }}
              >
                <img
                  style={{
                    width: "5vw",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                  src={require("../shared/assets/contract.png")}
                />
                <label
                  htmlFor="fileUpload"
                  style={{
                    textAlign: "left",
                    color: "black",
                    fontWeight: "700",
                    fontSize: isMobile ? "2.5rem" : "1rem",
                    cursor: "pointer",
                    paddingLeft: "0.5rem",
                  }}
                  onClick={(e) => e.stopPropagation()} // Stops event bubbling
                >
                  {t("contract-contract.upload1")}
                </label>
                <input
                  id="fileUpload"
                  type="file"
                  onClick={(e) => e.stopPropagation()} // Prevents duplicate trigger
                  onChange={(e) => {
                    onFileChange(e);
                    e.target.value = ""; // Reset input to allow re-selection of the same file
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </div>
        {success ? (
          validContract ? (
            illegal ? (
              <div style={illegalAnalysisStyle}>
                <img
                  style={analysisImageStyle}
                  src={require("../shared/assets/1904654_cancel_close_cross_delete_reject_icon.png")}
                />
                <h3 style={{ overflow: "auto", fontsize: "20px" }}>
                  {t("contract-contract.illegal-contract")}
                </h3>
                <button
                  style={appealButtonStyle}
                  onClick={() => handleContractClick(analysisResult)}
                >
                  <h3 style={{ color: "white" }}>
                    {t("contract-contract.start")}
                  </h3>
                  <img
                    src={require("../shared/assets/continue-arrow.png")}
                    style={{
                      width: isMobile ? "44px" : "22px",
                      height: isMobile ? "40px" : "20px",
                      objectFit: "cover",
                    }}
                  />
                </button>
                {console.log(analysisResult)}
              </div>
            ) : (
              <div style={legalAnalysisStyle}>
                <img
                  style={analysisImageStyle}
                  src={require("../shared/assets/check.png")}
                />
                <h3 style={{ overflow: "auto", fontsize: "20px" }}>
                  {t("contract-contract.legal-contract")}
                </h3>
                {console.log(analysisResult)}
              </div>
            )
          ) : (
            <div style={noContractAnalysisStyle}>
              <img
                style={analysisImageStyle}
                src={require("../shared/assets/no-contract.png")}
              />
              <h3 style={{ overflow: "auto", fontsize: "20px" }}>
                {t("contract-contract.no-contract")}
              </h3>
            </div>
          )
        ) : undefined}
        <div
          style={{
            paddingTop: "3vw",
            marginLeft: "3%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <h1 style={{ width: "100%" }}>{t("contract-contract.choose")}</h1>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "0.5rem",
            }}
          >
            {contracts.length > 0 ? (
              contracts.slice(0, 8).map((contract) => (
                <ContractElement
                  onClickAction={() => handleContractClick(contract)}
                  imgstyle={{
                    width: "4vw",
                    marginTop: "0.5rem", // Adds space between top edge and image
                    marginBottom: "1rem", // Adds space between image and label
                    paddingLeft: "1rem", // Adds space between image and left edge
                  }}
                  img={require("../shared/assets/contract2.png")}
                  key={contract.info.contractName}
                >
                  {contract.info.contractName}
                </ContractElement>
              ))
            ) : existingContracts ? (
              <p>{t("contract-contract.loading")}</p>
            ) : (
              <h2 style={{ color: "gray" }}>{t("contract-contract.none")}</h2>
            )}
          </div>
        </div>
      </div>
      {dialogError ? (
        <Dialog
          type="error"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
      <Account></Account>
    </div>
  );
};

const StartAnalysis = ({
  children,
  onSubmit,
  contractName,
  setContractName,
}) => {
  const isMobile = useResponsiveStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContractNameChange = (event) => {
    setContractName(event.target.value); // Update the contract name in parent state
  };

  return (
    <div>
      <DarkBlurredBackground />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "2rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: isMobile ? "80vw" : "40vw",
          textAlign: "left",
          height: "40vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>{t("contract-contract.name1")}</h1>
          <CancelButton onClick={() => navigate("/contract/")} />
        </div>
        <h2 style={{ color: "gray", marginTop: "1rem", marginBottom: "1rem" }}>
          {t("contract-contract.name2")}
        </h2>
        <h2 style={{ marginBottom: "1rem", fontSize: "10" }}>({children}) </h2>
        <Input
          style={{
            width: isMobile ? "70%" : "80%",
            fontWeight: 500,
            border: "1px solid lightgray",
            marginBottom: "1rem",
          }}
          placeholder={t("contract-contract.name")}
          value={contractName}
          onChange={handleContractNameChange} // Use the setter function
        />
        <button
          style={{
            border: "none",
            width: isMobile ? "20vw" : "15vw",
            height: isMobile ? "6rem" : "3.5rem",
            borderRadius: "20px",
            backgroundColor: contractName ? "black" : "lightgray",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            marginTop: "3%",
            cursor: "pointer",
          }}
          onClick={onSubmit}
          disabled={!contractName}
        >
          <h2 style={{ color: "white", marginLeft: "10%" }}>
            {t("contract-contract.analyze")}
          </h2>
          <img
            src={require("../shared/assets/continue-arrow.png")}
            style={{
              width: isMobile ? "44px" : "22px",
              height: isMobile ? "40px" : "20px",
              objectFit: "cover",
            }}
          />
        </button>
      </div>
    </div>
  );
};
