import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { storeAnalyticsEvent } from "../analytics";
import { useResponsiveStyles } from "./mobile";

const halfPageStyle = {
  height: "100vh",
  width: "50vw",
};

export const SplitPage = ({
  color = "#69ADFF",
  float = "right",
  style,
  children,
}) => {
  return (
    <div style={{ ...halfPageStyle, backgroundColor: color, float, ...style }}>
      {children}
    </div>
  );
};

export const SiteHead = ({ label }) => {
  return <h1>{label}</h1>;
};

export const SectionHead = ({ label, style }) => {
  const isMobile = useResponsiveStyles();

  const styles = {
    textAlign: "left",
    marginLeft: "3%",
    marginTop: isMobile ? "20%" : "5%",
    fontSize: isMobile ? "4rem" : "2rem",
    ...style,
  };

  return <h1 style={{ ...styles }}>{label}</h1>;
};

export const Input = ({
  type = "text",
  style = {},
  placeholder = "",
  inputMode = "text",
  value,
  ref,
  onChange,
  onEnter,
}) => {
  const location = useLocation();
  const isMobile = useResponsiveStyles();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  const handleChange = (event) => {
    storeAnalyticsEvent({
      category: location.pathname + location.searchsearch,
      action: "typed",
      label: placeholder,
    });
    onChange(event);
  };

  return (
    <input
      ref={ref}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(event) => handleChange(event)}
      inputMode={inputMode}
      onKeyDown={handleKeyDown}
      style={{
        padding: "10px",
        fontSize: isMobile ? "2.5rem" : "1rem",
        borderRadius: isMobile ? "15px" : "7px",
        border: "1px solid black",
        outline: "none",
        height: isMobile ? "7rem" : "3rem",
        width: "100%",
        ...style,
      }}
    />
  );
};

export const TextArea = ({
  type = "text",
  style = {},
  placeholder = "",
  inputMode = "text",
  value,
  onChange,
  onEnter,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <textarea
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      inputMode={inputMode}
      onKeyDown={handleKeyDown}
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "7px",
        border: "1px solid black",
        outline: "none",
        height: "3rem",
        width: "100%",
        resize: "none",
        ...style,
      }}
    />
  );
};

export const SelectionInput = ({
  options = [],
  style = {},
  value,
  onChange,
}) => {
  const isMobile = useResponsiveStyles();

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <select
        value={value}
        onChange={onChange}
        style={{
          padding: "10px",
          fontSize: isMobile ? "2rem" : "1rem",
          borderRadius: isMobile ? "15px" : "7px",
          border: "1px solid black",
          outline: "none",
          height: isMobile ? "7rem" : "3rem",
          width: "100%",
          color: "black",
          backgroundColor: "transparent",
          appearance: "none",
          paddingLeft: "2rem",
          ...style,
        }}
      >
        {options.map((option, index) => (
          <option
            style={{ paddingLeft: isMobile ? "2%" : "" }}
            key={index}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          pointerEvents: "none",
        }}
      >
        ▼
      </div>
    </div>
  );
};

export const SmallInput = ({
  type = "text",
  style = {},
  placeholder = "",
  value,
  onChange,
}) => {
  const location = useLocation();
  const handleChange = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "typed",
      label: placeholder,
    });
    onChange();
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "7px",
        border: "1px solid black",
        outline: "none",
        height: "3rem",
        width: "90%",
        ...style,
      }}
    />
  );
};

export const Button = ({
  style = {},
  label = "placeholder",
  onClick,
  active = true,
}) => {
  const location = useLocation();
  const isMobile = useResponsiveStyles();

  const handleClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label: label,
    });
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      disabled={!active}
      style={{
        color: "white",
        backgroundColor: "black",
        padding: "10px 20px",
        fontSize: isMobile ? "3rem" : "1rem",
        border: "none",
        borderRadius: "7px",
        height: isMobile ? "5rem" : "3rem",
        width: "100%",
        cursor: "pointer",
        opacity: active ? 1 : 0.5,
        ...style,
      }}
    >
      {label}
    </button>
  );
};

export const Container = ({
  children,
  itemsPerRow = 1,
  itemWidth = "20vw",
  columnWidths = ["20%", "80%"],
  gap = "16px",
  margin = "5px",
  alignItems = "center",
  style = {},
  onClick,
}) => {
  const gridTemplateColumns =
    columnWidths.length === itemsPerRow
      ? columnWidths.join(" ")
      : `repeat(${itemsPerRow}, minmax(0, 1fr))`;

  return (
    <div
      style={{
        alignItems,
        display: "grid",
        gridTemplateColumns,
        gap, // spacing between items
        margin, // outer margin for the container
        width: `calc(${itemsPerRow} * ${itemWidth} + (${itemsPerRow} - 1) * ${gap})`,
        ...style,
      }}
      onClick={onClick}
    >
      {React.Children.map(children, (child) => (
        <div style={{ width: itemWidth }}>{child}</div>
      ))}
    </div>
  );
};

export const HyperLink = ({
  href,
  children,
  style = {},
  onClick,
  externalLink = false,
}) => {
  const navigate = useNavigate();
  const isMobile = useResponsiveStyles();

  const handleClick = () => {
    if (externalLink) {
      window.location.href = href;
    } else {
      navigate(href);
      onClick();
    }
  };

  return (
    <a
      onClick={() => handleClick()}
      style={{
        color: "black",
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: isMobile ? "2.5rem" : "1rem",
        ...style,
      }}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export const CancelButton = ({ onClick, style }) => {
  const isMobile = useResponsiveStyles();

  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    ...style,
  };

  const imageStyle = {
    width: isMobile ? "70px" : "30px",
    height: isMobile ? "70px" : "30px",
    objectFit: "cover",
  };

  const location = useLocation();
  const handleCancelClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label: "Cancel",
    });
    onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleCancelClick}>
      <img src={require("./assets/cancel.png")} style={imageStyle} />
    </button>
  );
};

export const BackButton = ({
  onClick,
  style,
  target,
  colorScheme = "light",
}) => {
  const navigate = useNavigate();
  const image = {
    light: require("../shared/assets/arrow.png"),
    //dark: require("../shared/assets/back-arrow-dark.png"),
  }[colorScheme];

  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    ...style,
  };

  const imageStyle = {
    width: colorScheme == "light" ? "22px" : "27px",
    height: "30px",
    objectFit: "fill", // Options: fill, contain, cover, none, scale-down
  };

  const handleBackClick = () => {
    navigate(target);
  };

  return (
    <button style={buttonStyle} onClick={handleBackClick}>
      <img src={image} style={imageStyle} />
    </button>
  );
};

export const ContinueButton = ({ onClick, style, colorScheme = "light" }) => {
  const isMobile = useResponsiveStyles();

  const image = {
    light: require("../shared/assets/continue-arrow.png"),
    dark: require("../shared/assets/continue-arrow-dark.png"),
  }[colorScheme];

  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    cursor: "pointer",
    ...style,
  };

  const imageStyle = {
    width:
      colorScheme == "light"
        ? isMobile
          ? "44px"
          : "22px"
        : isMobile
          ? ""
          : "27px",
    height: isMobile ? "40px" : "20px",
    objectFit: "cover",
  };

  const handleContinueClick = () => {
    onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleContinueClick}>
      <img src={image} style={imageStyle} />
    </button>
  );
};
export const ImageButton = ({
  onClick,
  style = {},
  label,
  colorScheme = "light",
  imageSrc,
}) => {
  const defaultImage = {
    light: require("../shared/assets/continue-arrow.png"),
    dark: require("../shared/assets/continue-arrow-dark.png"),
  }[colorScheme];

  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    color: "black",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    ...style,
  };

  const imageStyle = {
    width: colorScheme === "light" ? "22px" : "27px",
    height: "20px",
    objectFit: "cover",
    marginLeft: "8px",
  };

  const location = useLocation();
  const handleButtonClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label,
    });
    onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleButtonClick}>
      {label}
      <img
        src={imageSrc || defaultImage}
        style={imageStyle}
        alt="button icon"
      />
    </button>
  );
};

export const BlurredBackground = ({}) => {
  const BlurredBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    zindex: 20;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  `;

  return <BlurredBackground></BlurredBackground>;
};

export const DarkBlurredBackground = () => {
  const DarkBlurredBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    zindex: 2000;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  `;

  return <DarkBlurredBackground></DarkBlurredBackground>;
};

export const ContractElement = ({
  onClickAction,
  imgstyle,
  img,
  children,
  title = "",
}) => {
  const contractButtonStyle = {
    backgroundColor: "transparent",
    border: "1px solid #868080",
    borderRadius: "20px",
    width: "20vw",
    marginTop: "2rem",
    display: "flex", // Stacks elements vertically
    flexDirection: "column", // Ensures vertical alignment
    alignItems: "flex-start", // Aligns to the left
    padding: "1rem", // Adds some spacing inside the box
    height: "auto", // Adjusts based on content,
    cursor: "pointer",
  };
  return (
    <div style={contractButtonStyle} onClick={onClickAction}>
      {title ? (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "10% 90%", // Ensures a fixed ratio
              alignItems: "start", // Ensures content aligns at the top
            }}
          >
            <img
              style={{
                width: "100%", // Ensures it fills its grid column properly
                height: "auto", // Maintains aspect ratio
                objectFit: "contain", // Ensures consistency without distortion
                alignSelf: "start", // Ensures image stays at the top
              }}
              src={img}
              alt="Image"
            />

            <h2
              style={{
                alignSelf: "start", // Ensures text is always at the top
                paddingLeft: "0.5rem",
                maxWidth: "90%",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                hyphens: "auto",
                margin: 0, // Removes default margin that could shift text
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </h2>
          </div>
          <label
            style={{
              textAlign: "left",
              paddingTop: "0.5rem",
              fontSize: "15",
              color: "gray",
              fontWeight: "700",
              cursor: "pointer",
              paddingLeft: "0.5rem", // Adds space between image and label
            }}
          >
            {children}
          </label>
        </>
      ) : (
        <>
          <img style={imgstyle} src={img}></img>
          <label
            style={{
              textAlign: "left",
              color: "black",
              fontWeight: "700",
              cursor: "pointer",
              paddingLeft: "0.5rem", // Adds space between image and label
            }}
          >
            {children}
          </label>
        </>
      )}
    </div>
  );
};
/*
export const Spinner = ({ children }) => {
  return (
    <div style={spinnerstyles.spinnerContainer}>
      <div style={spinnerstyles.spinner}></div>
      <span style={spinnerstyles.loadingText}>{children}</span>
    </div>
  );
};
const spinnerstyles = {
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    fontSize: "10rem",
    fontWeight: "bold",
    color: "#69ADFF",
    height: "100%",
    width: "100%",
  },
  spinner: {
    border: "0.07em solid #f3f3f3",
    borderTop: "0.07em solid #69ADFF",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  loadingText: {
    fontSize: "1rem",
    color: "white",
    fontWeight: "600",
  },
};

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(
  `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`,
  styleSheet.cssRules.length
);
*/
