import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
export const AuthError = ({ visibility, type }) => {
  const { t, i18n } = useTranslation();
  const errorMessages = {
    emailMissing: t("auth-components.missing"),
    passwordMissing: t("auth-components.password"),
    nameMissing: t("auth-components.name"),
    cityMissing: t("auth-components.residence"),
    inputMissing: t("auth-components.email-password"),
    invalidEmail: t("auth-components.email"),
    weakPassword: t("auth-components.invalid-password"),
    denied: t("auth-components.denied"),
    default: t("auth-components.error"),
  };

  const errorMessage = errorMessages[type] || errorMessages.default;

  if (!visibility) return null;

  return <p style={{ color: "#721c24" }}>{errorMessage}</p>;
};

export const HomeButton = ({ style }) => {
  const isMobile = useResponsiveStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/"); // TODO: Redirect to the specified URL
  };

  const desktopStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    color: "black",
    marginLeft: "20%",
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const mobileStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 900,
    fontSize: "10rem",
    color: "black",
    fontStyle: "italic",
    textAlign: "left",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: isMobile ? 0 : "",
    marginLeft: "10%",
    marginTop: isMobile ? "0" : "3%",
  };

  return (
    <button
      onClick={() => handleClick()}
      style={isMobile ? mobileStyle : desktopStyle}
    >
      {isMobile ? "s" : "smartmieter"}
    </button>
  );
};
