import { Elements } from "@stripe/react-stripe-js";
import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Checkout } from "../../Payment";
import { loadStripe } from "@stripe/stripe-js/dist";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Payment = () => {
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 6);

  useAnalytics();

  const stripePromise = loadStripe(
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm"
  );

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "5%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: "1.1rem",
            bottom: 0,
            marginLeft: "10rem",
          }}
        >
          {t("signup.comp-payment.text1")}
        </p>

        <Elements stripe={stripePromise}>
          <Checkout
            return_url={"https://smartmieter.com/funnel?step=7"}
          ></Checkout>
        </Elements>
      </Container>
    </div>
  );
};
