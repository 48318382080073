import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
};

export const storeAnalyticsEvent = (event) => {
  ReactGA.event({
    category: event.category,
    action: event.action,
    label: event.label,
  });
};

export const useMouseflow = () => {
  useEffect(() => {
    if (!window._mfq) {
      window._mfq = window._mfq || [];

      const mfScript = document.createElement("script");
      mfScript.type = "text/javascript";
      mfScript.defer = true;
      mfScript.src =
        "//cdn.mouseflow.com/projects/da9f5b2a-517b-4ee5-ae7f-b1cf73ee1359.js";
      document.getElementsByTagName("head")[0].appendChild(mfScript);

      mfScript.onload = () => {
        console.log("Mouseflow script loaded successfully!");
      };

      mfScript.onerror = (error) => {
        console.error("Error loading Mouseflow script:", error);
      };
    }
  }, []);

  return null;
};
