const { useTranslation } = require("react-i18next");
const { useResponsiveStyles } = require("../../shared/mobile");
const { Container } = require("../../shared/components");
const { Pill } = require("../../App/components");

export const CorrespondenceCard = ({
  type,
  date,
  onClick,
  index,
  offer = false,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();

  const tableRowStyle = {
    backgroundColor: "#E1EFFF",
    width: isMobile ? "78vw" : "32vw",
    height: isMobile ? "18vh" : "10vh",
    borderRadius: isMobile ? "25px" : "10px",
    marginTop: "2%",
  };

  const image = {
    landlord: {
      src: require("../assets/person-blue.png"),
      width: isMobile ? "25%" : "40%",
    },
    tenant: {
      src: require("../assets/letter-blue.png"),
      width: isMobile ? "30%" : "45%",
    },
    property_management: {
      src: require("../assets/house-blue.png"),
      width: "35%",
    },
    attorney: {
      src: require("../assets/law-blue.png"),
      width: isMobile ? "20%" : "35%",
    },
  }[type];

  const handleClick = () => {
    onClick(index);
  };

  const title = {
    landlord: t("cases-corr.landlord"),
    property_management: t("cases-corr.management"),
    tenant: t("cases-corr.renter"),
    attorney: t("cases-corr.attorney"),
  }[type];

  return (
    <div style={tableRowStyle} onClick={handleClick}>
      <Container
        itemWidth="auto"
        alignItems="auto"
        margin="0"
        gap="0"
        columnWidths={isMobile ? ["20%", "80%", "100%"] : ["20%", "50%", "30%"]}
        itemsPerRow={isMobile ? 2 : 3}
      >
        <img
          src={image.src}
          style={{
            marginLeft: "20%",
            marginTop: isMobile ? (!offer ? "30%" : "15%") : "25%",
            width: image.width,
          }}
        ></img>
        <Container
          itemWidth="auto"
          gap="0"
          margin="0"
          style={{
            marginTop: isMobile ? (!offer ? "20%" : "5%") : "2%",
            width: "90%",
          }}
        >
          <p
            style={{
              fontWeight: 800,
              fontSize: isMobile ? "2.5rem" : "1.1rem",
              margin: 0,
              marginTop: isMobile ? "10%" : "6%",
              bottom: 0,
            }}
          >
            {title}
          </p>
          <p
            style={{
              marginTop: "4%",
              fontSize: isMobile ? "1.5rem" : "1rem",
            }}
          >
            {date}
          </p>
        </Container>
        {offer ? (
          <Pill
            style={{
              marginLeft: isMobile ? "10%" : "-3%",
              whiteSpace: "nowrap",
              width: "90%",
              marginTop: isMobile ? "10%" : "2%",
            }}
            label={t("cases-corr.offer1")}
          ></Pill>
        ) : undefined}
      </Container>
    </div>
  );
};
