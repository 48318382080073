export const prodStageConfig = {
  USER_POOL_ID: "eu-central-1_1u11K3ZKz",
  CLIENT_ID: "67aulr9q9dsghs1a9sn6ghin89",
  API_URL: "https://v1xkhncdf7.execute-api.eu-central-1.amazonaws.com",
  WEBSOCKET_URL: "wss://nqz5tlkudk.execute-api.eu-central-1.amazonaws.com/prod",
  STRIPE_PUBLISHABLE_KEY:
    "pk_live_51QGfVnBQODzeSQeQXagR6ipzuTcxnwdv1CukoSHhrGH3y9UnqGjwCZPQhG0FFoqy4iraNcXe1hXZYzV8F2HGQpdU005Vq5mp2p",
  STRIPE_PRICE_IDS: {
    basic: {
      monthly: "price_1QlrtkBQODzeSQeQs1vg2Ube",
      yearly: "price_1QlsaKBQODzeSQeQbhV9M1JQ",
    },
    pro: "price_1Qls7ZBQODzeSQeQE6SEHqfr",
  },
};
