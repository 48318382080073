import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import { getStageConfig } from "../config/config";
import { Header } from "../shared/header";
import { ContractElement, BackButton, Container } from "../shared/components";
import "./vertrag.css";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import axios from "axios";
import { Send } from "../Cases/send";
import { Account } from "../Account";

export const ContractDetails = () => {
  const { t } = useTranslation();
  const { contractID } = useParams(); // Get contract ID from URL
  const { user, jwtToken } = useAuth();
  const config = getStageConfig();
  const [recipientType, setRecipientType] = useState("");
  const [optionSelected, setOptionSelected] = useState(false);

  const location = useLocation();
  const contract = location.state?.contract;
  const [contractData, setContractData] = useState(contract || null); // Use passed contract if available
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClauses, setSelectedClauses] = useState([]); // State to track selected clauses
  const [buttonEnabled, setButtonEnabled] = useState(false); // Track whether the button is enabled
  const [clauseClicked, setClauseClicked] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const toggleClauseSelection = (clause) => {
    setSelectedClauses((prevSelectedClauses) => {
      const isSelected = prevSelectedClauses.includes(clause);
      if (isSelected) {
        return prevSelectedClauses.filter((item) => item !== clause);
      } else {
        return [...prevSelectedClauses, clause];
      }
    });
  };

  useAnalytics();

  const handleClauseClick = async (reason) => {
    setClauseClicked(true);

    try {
      const result = await axios.post(
        `${config.API_URL}/default/cases`,
        {
          reason,
          status: "opened",
          tenantId: user?.tenantId,
          tenantName: user?.fullName,
          language: user?.language,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(`result: ${JSON.stringify(result)}`);
      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };
  const handleVisibility = () => {
    setClauseClicked(false);
  };

  useEffect(() => {
    // Enable/disable button based on whether any clauses are selected
    console.log(contractData);
    setButtonEnabled(selectedClauses.length > 0);
  }, [selectedClauses]);

  return (
    <div className="vertrag-app">
      {clauseClicked ? (
        <Send
          recipientType={recipientType}
          landlordInformation={
            user ? user?.landlord || user?.propertyManagement : undefined
          }
          reason={"illegal_contract"}
          caseId={caseId}
          onCancel={() => handleVisibility()}
        ></Send>
      ) : undefined}
      <Header staticColor={true} />
      <div style={{ marginTop: "5rem" }}>
        <div style={{ marginLeft: "3%" }}>
          <div style={{ display: "grid", gridTemplateColumns: "3% 97%" }}>
            <BackButton
              style={{ padding: "0px", justifyContent: "flex-start" }}
              target={"/contract"}
            />
            <h1>{contractData?.contractName || "Loading..."}</h1>{" "}
          </div>
          <h2 style={{ fontSize: "1rem", color: "lightgray" }}>
            {contract.clauses
              ? t("contract-dets.clauses1")
              : t("contract-dets.clauses2")}
          </h2>
          <div>
            {console.log(contractData)}
            {contractData.illegalClauses ? (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "0.5rem",
                  }}
                >
                  {contractData.illegalClauses.map((clause) => (
                    <ContractElement
                      onClickAction={() => toggleClauseSelection(clause)}
                      imgstyle={{
                        width: "40%",
                        marginTop: "0.5rem", // Adds space between top edge and image
                        marginBottom: "1rem", // Adds space between image and label
                        paddingLeft: "1rem", // Adds space between image and left edge
                      }}
                      title={clause.label}
                      img={
                        selectedClauses.includes(clause)
                          ? require("../shared/assets/Checked.png")
                          : require("../shared/assets/Unchecked.png")
                      }
                    >
                      {clause.analysis}
                    </ContractElement>
                  ))}
                </div>
                <h2 style={{ fontSize: "1rem", color: "lightgray" }}>
                  {t("contract-dets.challenge")}
                </h2>
                <button
                  style={{
                    backgroundColor: buttonEnabled ? "black" : "lightgray",
                    color: "white",
                    border: "none",
                    borderRadius: "20px",
                    width: "22vw",
                    padding: "1rem",
                    cursor: buttonEnabled ? "pointer" : "not-allowed",
                  }}
                  disabled={!buttonEnabled}
                  onClick={() => handleClauseClick(contractData.name)}
                >
                  {t("contract-dets.submit")}
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Account></Account>
    </div>
  );
};
