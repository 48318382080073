import {
  CancelButton,
  DarkBlurredBackground,
  HyperLink,
} from "../shared/components";
import { Container } from "../shared/components";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useAuth } from "../Auth/provider";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Account = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const accountVisible = searchParams.get("account") === "true";

  const handleClick = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("account");
    setSearchParams(newParams);
  };

  const styles = {
    backgroundColor: "white",
    height: "100%",
    width: "25%",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 20,
  };

  const textStyles = {
    fontWeight: "600",
    textAlign: "left",
    position: "absolute",
    bottom: "50px",
  };

  useAnalytics();

  return (
    <div>
      {accountVisible ? (
        <div>
          <DarkBlurredBackground></DarkBlurredBackground>
          <div style={styles}>
            <Container itemsPerRow={1} style={{ marginLeft: "10%" }}>
              <Container itemsPerRow={2} style={{ marginTop: "5%" }}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  {t("acc-index.account")}
                </p>
                <CancelButton onClick={() => handleClick()}></CancelButton>
              </Container>

              <p style={{ ...textStyles, top: "100px" }}>
                {user ? user.firstName + " " + user.lastName : ""}
              </p>

              <p style={{ ...textStyles, top: "140px" }}>
                {user ? user.city : ""}
              </p>

              <p style={{ ...textStyles, top: "180px" }}>
                {user ? user.email : ""}
              </p>

              <Container itemsPerRow={1}>
                <p style={{ ...textStyles, fontStyle: "italic" }}>
                  {t("acc-index.since")} {user ? user.memberSince : ""}
                </p>
                <HyperLink
                  href={"/imprint"}
                  style={{
                    ...textStyles,
                    textDecoration: "none",
                    fontWeight: "700",
                    bottom: "20px",
                    marginLeft: "11%",
                    left: 0,
                  }}
                  onClick={() => navigate("/about")}
                >
                  {t("acc-index.about")}
                </HyperLink>
              </Container>
            </Container>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
