import { Header } from "../shared/header";
import React, { useState } from "react";
import "./chat.css";
import { useAnalytics } from "../analytics";
import {
  BlurredBackground,
  Button,
  Container,
} from "../shared/components";
import { useEffect, useRef } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getStageConfig } from "../config/config";
import { useAuth } from "../Auth/provider";
import ReactMarkdown from "react-markdown";
import { Send } from "../Cases/send";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import axios from "axios";
import { useResponsiveStyles } from "../shared/mobile";
import { Account } from "../Account";

export const Chat = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [minderungKeys, setMinderungKeys] = useState({});
  const [recipientType, setRecipientType] = useState("");
  const [acceptSuggestionSelected, setAcceptSuggestionSelected] =
    useState(false);
  const [reductionClicked, setReductionClicked] = useState(false);
  const [correspondenceVisible, setCorrespondenceVisible] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [reductionReason, setReductionReason] = useState("");
  const [chatId, setChatId] = useState("");
  const ws = useRef(null);

  const { user, jwtToken } = useAuth();
  const config = getStageConfig();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const question = queryParams.get("question");
  const newChat = queryParams.get("new");
  const chatIdParam = queryParams.get("chatId");

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeWebSocket = () => {
    ws.current = new ReconnectingWebSocket(
      `${config.WEBSOCKET_URL}?token=${jwtToken}`
    );
  };

  useEffect(() => {
    initializeWebSocket();

    if (newChat) {
      createChat();
    }

    if (question) {
      setInput(question);
      sendMessage();
    }

    if (chatIdParam) {
      getMessages(chatIdParam);
    }

    ws.current.onopen = () => {
      console.log("Connected to WebSocket");
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(`response: ${JSON.stringify(response)}`);
      const botMessage = {
        sender: "bot",
        reduction: response.rentReduction,
        message:
          response.message === "Internal server error" ? (
            t("chat-chat.error")
          ) : (
            <ReactMarkdown children={response.message}></ReactMarkdown>
          ),
      };
      console.log(`reduction: ${response.rentReduction}`);
      setReductionReason(response.rentReduction);
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
      setMinderungKeys((prevKeys) => ({
        ...prevKeys,
        [response.message]: response.rentReduction,
      }));
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket closed");
      setTimeout(initializeWebSocket, 5000);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [question]);

  const handleShowAcceptSuggestion = (status) => {
    setAcceptSuggestionSelected(status);
    setCorrespondenceVisible(!status);
  };

  const handleSuggestionClicked = (event) => {
    setInput(event.target.innerText);
    sendMessage();
  };

  const createChat = async () => {
    try {
      const result = await axios.post(
        `${config.API_URL}/default/chat`,
        {
          userId: user?.tenantId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setChatId(result.data.chatId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const getMessages = async (chatId) => {
    try {
      const result = await axios.get(
        `${config.API_URL}/default/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setMessages((prevMessages) => {
        const allMessages = [...prevMessages, ...result.data];
        const uniqueMessages = Array.from(
          new Set(allMessages.map((msg) => msg.id))
        ).map((id) => allMessages.find((msg) => msg.id === id));
        return uniqueMessages;
      });
    } catch (error) {}
  };

  const sendMessage = async () => {
    console.log("sendMessage");

    if (
      ws.current &&
      ws.current.readyState === WebSocket.OPEN &&
      input.trim()
    ) {
      setIsTyping(true);
      const userMessage = { sender: "user", message: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      ws.current.send(
        JSON.stringify({
          action: "sendMessage",
          message: input,
          userId: user?.tenantId ?? "",
          chatId,
        })
      );

      setInput("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  useAnalytics();

  const suggestionPillStyle = {
    backgroundColor: "#AAD0FF",
    height: isMobile ? "4rem" : "3rem",
    fontSize: isMobile ? "2rem" : "",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    whiteSpace: isMobile ? "" : "nowrap",
    borderRadius: "100px",
    fontWeight: "bold",
  };

  const handleShowCorrespondence = () => {
    setCorrespondenceVisible(false);
  };

  const handleReductionClick = async (reason) => {
    setReductionClicked(true);

    try {
      const result = await axios.post(
        `${config.API_URL}/default/cases`,
        {
          reason,
          status: "opened",
          tenantId: user?.tenantId,
          tenantName: user?.fullName,
          language: user?.language,
          chatId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(`result: ${JSON.stringify(result)}`);
      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleVisibility = () => {
    setReductionClicked(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header staticColor={true}></Header>
      <div id="parent" style={{ overflowX: "hidden", height: "90%" }}>
        {/* TODO: fix this fucking shit*/}
        <div id="wide" style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div
              style={{
                margin: isMobile ? "3%" : "1%",
                marginTop: isMobile ? "30%" : "10%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {messages.length === 0 && !chatIdParam ? (
                <div>
                  <h1
                    style={{
                      margin: 0,
                      textAlign: "center",
                      marginTop: isMobile ? "50%" : "20%",
                      fontSize: isMobile ? "4rem" : "2.5rem",
                      bottom: 0,
                    }}
                  >
                    smartmieter Chat
                  </h1>
                  <h2
                    style={{
                      color: "gray",
                      textAlign: "center",
                      fontWeight: 400,
                      fontSize: isMobile ? "3.5rem" : "",
                      marginTop: "1%",
                      margin: 0,
                    }}
                  >
                    {t("chat-chat.help")}
                  </h2>
                  <Container
                    style={{
                      width: isMobile ? "90%" : "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "2%",
                    }}
                    itemsPerRow={2}
                    columnWidths={["50%", "50%"]}
                    itemWidth="auto"
                  >
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>{t("chat-chat.raise")}</p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>{t("chat-chat.water")}</p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>{t("chat-chat.dogs")}</p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>{t("chat-chat.servicecharges")}</p>
                    </div>
                  </Container>
                </div>
              ) : undefined}
              {messages.map((message, index) => (
                <Message
                  message={message.message}
                  sender={message.sender}
                  reduction={message.reduction}
                  index={index}
                  reductionClicked={() =>
                    handleReductionClick(message.reduction)
                  }
                ></Message>
              ))}
              {isTyping && (
                <div className="message-bubble bot message bot-message">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>
      </div>
      <ChatInput
        input={input}
        inputChanged={(e) => setInput(e)}
        sendMessage={sendMessage}
      ></ChatInput>
      {reductionClicked ? (
        <Send
          recipientType={recipientType}
          landlordInformation={
            user ? user?.landlord || user?.propertyManagement : undefined
          }
          reason={reductionReason.replace(RegExp('"', "g"), "")}
          caseId={caseId}
          onCancel={() => handleVisibility()}
        ></Send>
      ) : undefined}
      <Account></Account>
    </div>
  );
};

export const ChatInput = ({ input, inputChanged, sendMessage }) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const styles = {
    display: "flex",
    padding: "10px",
    position: "fixed",
    width: isMobile ? "97%" : "99%",
    marginBottom: 0,
    bottom: 0,
    backgroundColor: "#ffffff",
    borderTop: "1px solid #ddd",
  };

  const inputStyle = {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    paddingLeft: isMobile ? "40px" : "20px",
    borderRadius: isMobile ? "100px" : "20px",
    fontSize: isMobile ? "2.5rem" : "1rem",
    height: isMobile ? "8.5rem" : "",
  };

  const buttonStyle = {
    marginLeft: "10px",
    padding: isMobile ? "10px 50px" : "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: isMobile ? "100px" : "20px",
    fontSize: isMobile ? "2.5rem" : "1rem",
    cursor: "pointer",
    transition: "background-color 0.2s",
  };

  return (
    <div style={styles}>
      <input
        style={inputStyle}
        type="text"
        value={input}
        onChange={(e) => inputChanged(e.target.value)}
        placeholder={t("chat-chat.message")}
        onKeyDown={(e) => e.key === "Enter" && sendMessage()}
      />
      <button style={buttonStyle} onClick={sendMessage}>
        {t("chat-chat.send")}
      </button>
    </div>
  );
};

export const Message = ({
  sender,
  reduction,
  message,
  index,
  reductionClicked,
}) => {
  const isMobile = useResponsiveStyles();
  const { t } = useTranslation();

  const botStyle = {
    alignSelf: "flex-start",
    backgroundColor: "#e5e5ea",
    color: "#000000",
    borderRadius: "15px 15px 15px 0px",
  };

  const userStyle = {
    alignSelf: "flex-end",
    backgroundColor: "#007bff",
    color: "#ffffff",
    float: "right",
    position: "relative",
    borderRadius: "15px 15px 0px 15px",
  };

  const messageStyle = {
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    maxWidth: "75%",
    padding: isMobile ? "15px 40px" : "10px 15px",
    borderRadius: isMobile ? "50px" : "18px",
    lineHeight: "1.4",
    wordWrap: "break-word",
    fontSize: isMobile ? "2.6rem" : "1rem",
  };

  const buttonStyle = {
    width: isMobile ? "70%" : "15rem",
    marginTop: isMobile ? "2%" : "3%",
    fontSize: isMobile ? "2rem" : "",
    padding: isMobile ? "20px 40px" : "",
    fontWeight: 500,
    height: isMobile ? "7rem" : "3rem",
    borderRadius: "100px",
  };

  return (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "1rem",
        maxWidth: "80%",
        marginBottom: "1rem",
        ...messageStyle,
        ...(sender === "user" ? userStyle : botStyle),
      }}
    >
      <span style={{ width: " 100%", borderRadius: "4px", overflowY: "auto" }}>
        {message}
      </span>
      {reduction && reduction !== "False" && sender !== "user" ? (
        <Button
          label={t("chat-chat.case")}
          style={buttonStyle}
          onClick={() => reductionClicked(reduction)}
        ></Button>
      ) : undefined}
    </div>
  );
};
