export const devStageConfig = {
  USER_POOL_ID: "eu-central-1_oeAIZtaQb",
  CLIENT_ID: "4jhsl6udg3gebomjnefqc9fake",
  API_URL: "https://nn1pqzxas4.execute-api.eu-central-1.amazonaws.com",
  WEBSOCKET_URL: "wss://j8fxctaxu6.execute-api.eu-central-1.amazonaws.com/dev",
  STRIPE_PUBLISHABLE_KEY:
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm",
  STRIPE_PRICE_IDS: {
    basic: {
      monthly: "price_1QZyq7BQODzeSQeQrADlxV6h",
      yearly: "price_1QmlHJBQODzeSQeQAsaAzMjb",
    },
    pro: "price_1QmlHqBQODzeSQeQacoLEHMq",
  },
};
