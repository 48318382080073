import { useAnalytics } from "../../../analytics";
import { Pill } from "../../../App/components";
import { Container, ImageButton } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const ServiceCharge = ({
  highServiceChargeClicked,
  highRentClicked,
}) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  useAnalytics();

  const pillStyle = {
    fontWeight: 600,
    bottom: 0,
    whiteSpace: "nowrap",
    width: "70%",
    height: isMobile ? "6.5rem" : "",
  };

  const imageButtonStyle = {
    backgroundColor: "black",
    borderRadius: isMobile ? "20px" : "10px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    marginTop: isMobile ? "22rem" : "14rem",
    fontSize: isMobile ? "2rem" : "1.2rem",
    width: isMobile ? "30rem" : "",
    height: isMobile ? "6rem" : "3.3rem",
  };

  return (
    <div
      style={{
        height: isMobile ? "100rem" : "40rem",
        width: "100%",
        marginTop: "10%",
      }}
    >
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="auto"
        style={{ marginLeft: "10%", width: "90%" }}
        columnWidths={isMobile ? ["100%", "100%"] : ["50%", "50%"]}
      >
        <Container margin="0" gap="0" itemsPerRow={1} itemWidth="auto">
          <Pill
            style={{
              fontWeight: 600,
              bottom: 0,
              whiteSpace: "nowrap",
              width: isMobile ? "80%" : "50%",
            }}
            label={t("home.comp-service.text1")}
          ></Pill>
          <h1
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: isMobile ? "6rem" : "4rem",
              whiteSpace: "nowrap",
              height: "3.5rem",
              marginTop: isMobile ? "1.5rem" : "",
              top: 0,
            }}
          >
            {t("home.comp-service.title1")}
          </h1>
          <p
            style={{
              marginTop: "6rem",
              position: "absolute",
              width: isMobile ? "70%" : "30%",
              height: isMobile ? "5rem" : "auto",
              fontSize: isMobile ? "2rem" : "1.2rem",
            }}
          >
            {t("home.comp-service.text2")}
          </p>
          <ImageButton
            style={imageButtonStyle}
            onClick={highRentClicked}
            label={t("home.comp-service.text3")}
          ></ImageButton>
        </Container>
        <Container
          margin="0"
          gap="0"
          itemsPerRow={1}
          itemWidth="auto"
          style={{ marginTop: isMobile ? "80%" : 0 }}
        >
          <Pill
            style={{
              fontWeight: 600,
              bottom: 0,
              whiteSpace: "nowrap",
              width: isMobile ? "80%" : "50%",
            }}
            label={t("home.comp-service.text4")}
          ></Pill>
          <h1
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: isMobile ? "6rem" : "4rem",
              height: isMobile ? "4.5rem" : "3.5rem",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              marginTop: isMobile ? "1.5rem" : "",
              top: 0,
            }}
          >
            {t("home.comp-service.title2")}
          </h1>
          <p
            style={{
              marginTop: isMobile ? "10rem" : "6rem",
              position: "absolute",
              height: isMobile ? "6rem" : "auto",
              width: isMobile ? "70%" : "30%",
              fontSize: isMobile ? "2rem" : "1.2rem",
            }}
          >
            {t("home.comp-service.text5")}
          </p>
          <ImageButton
            style={imageButtonStyle}
            onClick={highServiceChargeClicked}
            label={t("home.comp-service.text6")}
          ></ImageButton>
        </Container>
      </Container>
    </div>
  );
};
