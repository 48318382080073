import { Container, ImageButton } from "../../../shared/components";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../../shared/mobile";

export const Numbers = ({ onClick }) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  return (
    <div style={{ height: isMobile ? "100vw" : "70vh" }}>
      <h1
        style={{
          margin: 0,
          marginLeft: "10%",
          maxWidth: "80%",
          fontSize: isMobile ? "4.5rem" : "3.5rem",
          fontWeight: 800,
        }}
      >
        {t("home.comp-numbers.fact1")}
        <br></br> {t("home.comp-numbers.fact2")} <br></br>
        {t("home.comp-numbers.fact3")}
      </h1>

      <ImageButton
        style={{
          width: isMobile ? "45%" : "20%",
          fontSize: isMobile ? "3rem" : "1.5rem",
          marginLeft: "10%",
          top: 0,
          textAlign: "left",
        }}
        label={t("home.comp-numbers.text1")}
        colorScheme="dark"
        onClick={onClick}
      ></ImageButton>

      <Container
        itemsPerRow={3}
        itemWidth="auto"
        style={{
          marginLeft: "10%",
          width: isMobile ? "90%" : "70%",
          marginTop: "10%",
        }}
        margin="0"
      >
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: isMobile ? "3rem" : "2.5rem",
              bottom: 0,
            }}
          >
            24M
          </p>
          <p style={{ top: 0, fontSize: isMobile ? "1.5rem" : "1rem" }}>
            {t("home.comp-numbers.text2")}
          </p>
        </Container>
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: isMobile ? "3rem" : "2.5rem",
              bottom: 0,
            }}
          >
            20%
          </p>
          <p style={{ top: 0, fontSize: isMobile ? "1.5rem" : "1rem" }}>
            {t("home.comp-numbers.text3")}
          </p>
        </Container>
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: isMobile ? "3rem" : "2.5rem",
              bottom: 0,
            }}
          >
            100€
          </p>
          <p style={{ top: 0, fontSize: isMobile ? "1.5rem" : "1rem" }}>
            {t("home.comp-numbers.text4")}
          </p>
        </Container>
      </Container>
    </div>
  );
};
