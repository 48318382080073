import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";

export const AboutUs = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const styles = {
    backgroundColor: "#69ADFF",
    height: "100vh",
    width: "100%",
    marginBottom: "5%",
    top: 0,
    margin: 0,
    position: "relative",
  };

  useAnalytics();

  const imageSrc = {
    mobile: require("../assets/smartmieter_room_mobile.png"),
    desktop: require("../assets/room-image.png"),
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header staticColor={true}></Header>
      <div style={styles}>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{ width: "100%", margin: 0 }}
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "10%",
              marginTop: "30%",
              zIndex: 10,
              widht: "80%",
            }}
          >
            <h2
              style={{
                fontSize: isMobile ? "4rem" : "2rem",
                fontWeight: 700,
                bottom: 0,
                padding: 0,
                lineHeight: "auto",
                color: "#E6E6E6",
              }}
            >
              {t("legal.about-index.text1")}
            </h2>
            <h1
              style={{
                fontSize: isMobile ? "9rem" : "5rem",
                fontWeight: 900,
                lineHeight: isMobile ? "" : "1rem",
                top: 0,
                marginBottom: 0,
                color: "white",
              }}
            >
              {t("legal.about-index.title1")}
            </h1>
            <h3
              style={{
                fontSize: isMobile ? "3.5rem" : "2rem",
                fontWeight: 500,
                marginTop: "5%",
                color: "white",
              }}
            >
              {t("legal.about-index.title2")}
            </h3>
          </div>
          <img
            style={{
              position: "absolute",
              width: "80%",
              bottom: 0,
              marginBottom: 0,
              marginTop: isMobile ? "-20%" : "auto",
              marginRight: 0,
              right: 0,
            }}
            src={isMobile ? imageSrc.mobile : imageSrc.desktop}
            alt="Room for aesthetics"
          ></img>
        </Container>
      </div>
      <div style={{ height: "150vh" }}>
        <p
          style={{
            fontWeight: 900,
            fontSize: isMobile ? "5rem" : "3rem",
            marginTop: "10%",
            marginLeft: isMobile ? "10%" : "50%",
            marginBottom: 0,
          }}
        >
          {t("legal.about-index.title3")}
        </p>
        <p
          style={{
            color: "#AAA6A6",
            fontWeight: 500,
            fontSize: isMobile ? "2.5rem" : "1rem",
            marginTop: "0",
            marginLeft: isMobile ? "10%" : "50%",
            width: "40%",
          }}
        >
          {t("legal.about-index.text2")}
        </p>

        <Container
          itemWidth={isMobile ? "100%" : "50%"}
          itemsPerRow={isMobile ? 1 : 2}
          style={{ marginTop: isMobile ? "30%" : "10%", marginLeft: "10%" }}
          columnWidths={isMobile ? ["100%"] : ["30%", "70%"]}
        >
          {!isMobile ? (
            <div
              style={{
                width: "25rem",
                height: "25rem",
                backgroundColor: "#69ADFF",
                borderRadius: "1.5rem",
              }}
            ></div>
          ) : undefined}
          <div>
            <p
              style={{
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: isMobile ? "2.5rem" : "1rem",
                marginTop: "0",
                marginBottom: 0,
                bottom: 0,
                marginLeft: isMobile ? 0 : "10%",
              }}
            >
              {t("legal.about-index.text3")}
            </p>
            <p
              style={{
                fontWeight: 800,
                fontSize: isMobile ? "5rem" : "3rem",
                whiteSpace: "nowrap",
                marginLeft: isMobile ? 0 : "10%",
                top: 0,
                marginTop: 0,
                botton: 0,
                marginBottom: 0,
              }}
            >
              FRIEDRICH BURK
            </p>
            <p
              style={{
                width: "80%",
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1rem",
                textAlign: "left",
                marginLeft: isMobile ? 0 : "10%",
                top: 0,
                marginTop: 0,
              }}
            >
              {t("legal.about-index.text4")}
            </p>
          </div>
        </Container>

        <Container
          itemWidth={isMobile ? "100%" : "50%"}
          itemsPerRow={isMobile ? 1 : 2}
          style={{ marginTop: isMobile ? "20%" : "10%" }}
          columnWidths={isMobile ? ["100%"] : ["60%", "40%"]}
        >
          <div style={{ marginLeft: isMobile ? "10%" : "30%", width: "100%" }}>
            <p
              style={{
                width: "100%",
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: isMobile ? "2.5rem" : "1rem",
                marginTop: "0",
                textAlign: isMobile ? "left" : "right",
                marginBottom: 0,
                bottom: 0,
              }}
            >
              {t("legal.about-index.text5")}
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 800,
                fontSize: isMobile ? "5rem" : "3rem",
                whiteSpace: "nowrap",
                textAlign: isMobile ? "left" : "right",
                top: 0,
                marginTop: 0,
                bottom: 0,
                marginBottom: 0,
              }}
            >
              MAX SCHNITZLER
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1rem",
                textAlign: isMobile ? "left" : "right",
                top: 0,
                marginTop: 0,
              }}
            >
              {t("legal.about-index.text6")}
            </p>
          </div>
          {!isMobile ? (
            <div
              style={{
                width: "25rem",
                height: "25rem",
                backgroundColor: "#69ADFF",
                borderRadius: "1.5rem",
              }}
            ></div>
          ) : undefined}
        </Container>
      </div>
      <p
        style={{
          marginLeft: "5%",
          fontWeight: 600,
          fontSize: isMobile ? "1.5rem" : "1rem",
        }}
      >
        smartmieter UG (haftungsbeschränkt): Erbachstraße 13, Magstadt 71106
      </p>
      <Footer></Footer>
    </div>
  );
};
