import { BlurredBackground, Button, ContinueButton } from "./shared/components";
import { CaseCard, NewsCard, Pill } from "./App/components";
import { Container } from "./shared/components";
import { Account } from "./Account";
import { Header } from "./shared/header";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CasesMenu } from "./Cases";
import { useAuth } from "./Auth/provider";
import { useAnalytics } from "./analytics";
import { getStageConfig } from "./config/config";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { Correspondence } from "./Cases/correspondence";
import { AcceptSuggestion } from "./Cases/acceptsuggestion";
import { mapReason, mapStatus } from "./shared/helpers";
import { useTranslation } from "react-i18next";
import "./i18n";
import { useResponsiveStyles } from "./shared/mobile";

ReactGA.initialize("G-7ZVT49R3P1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const App = () => {
  const isMobile = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const config = getStageConfig();
  const parentRef = useRef();
  const nestedRef = useRef();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [correspondenceVisible, setCorrespondenceVisible] = useState(false);
  const [acceptSuggestionSelected, setAcceptSuggestionSelected] =
    useState(false);
  const [accountVisible, setAccountVisible] = useState(false);
  const [casesVisible, setCasesVisible] = useState(false);
  const [Case, setCase] = useState();
  const [cases, setCases] = useState();
  const { user, jwtToken } = useAuth();

  const handlePillClick = (question) => {
    navigate(`/chat?question=${question}`);
  };

  const handleNavigateCasesOverview = () => {
    navigate("/cases");
  };

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const iGot99ProblemsAndABitchAintOne = [
    t("I have no warm water in my rental."),
    t("I have mold in my rental."),
    t("I want to enforce a rental reduction."),
    t("I am constantly disturbed by loud noises."),
    t("I received a large bill for servicecharges."),
    t("I regularly have electrical problems in my rental."),
    t("My heat doesn't work during the winter."),
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accountParam = queryParams.get("account");
    if (accountParam === "true") {
      setAccountVisible(true);
    }

    const fetchData = async () => {
      try {
        const cases = await axios.get(
          `${config.API_URL}/default/cases/${user?.tenantId}?status=opened,contact_landlord,contact_attorney,response_needed,chat_consultation`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        console.log(`cases: ${JSON.stringify(cases.data)}`);
        setCases(cases.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useAnalytics();

  const handleShowCorrespondence = (visible) => {
    setCorrespondenceVisible(visible);
    setCasesVisible(!visible);
    setMenuVisible(false);
  };

  const handleShowAcceptSuggestion = (status) => {
    setAcceptSuggestionSelected(status);
    setCorrespondenceVisible(!status);
  };

  const handleCasesClick = (index) => {
    setCasesVisible(!casesVisible);
    console.log(cases[index]);
    setCase(cases[index]);
  };

  const handleContractClick = () => {
    navigate("/contract");
  };

  const handleChatClicked = () => {
    navigate("/chat?new=true");
  };

  const getSubdivStyle = (index) => ({
    flex: 1,
    position: "relative",
    color: "black",
    textAlign: "center",
    height: "10rem",
    width: "10rem",
    transition: "transform 0.3s, z-index 0.3s, box-shadow 0.3s",
    zIndex: activeIndex === index ? 10 : 3 - index,
    transform: activeIndex === index ? "scale(1.05)" : "scale(1)",
    boxShadow: activeIndex === index ? "0 4px 10px rgba(0, 0, 0, 0.5)" : "none",
    marginLeft: index !== 0 ? "-50px" : "0",
    backgroundColor: "white",
    border: "1px solid black",
    height: "13rem",
    width: "19rem",
    borderRadius: "10px",
    cursor: "pointer",
  });

  return (
    <div
      className="App"
      ref={parentRef}
      style={{ marginTop: isMobile ? "30%" : "" }}
    >
      {/*<Navigation></Navigation>*/}

      <Header
        parentRef={parentRef}
        nestedRef={nestedRef}
        staticColor={true}
        onAccountClick={() => setAccountVisible(true)}
      ></Header>

      <Container
        style={{
          backgroundColor: "#16171D",
          borderRadius: isMobile ? "30px" : "20px",
          marginLeft: "5%",
          height: isMobile ? "40rem" : "20rem",
          marginTop: "10%",
          width: "90%",
          alignItems: "auto",
        }}
        itemsPerRow={2}
        columnWidths={["50%", "50%"]}
        itemWidth="auto"
        gap="0"
        margin="0"
      >
        <p
          style={{
            color: "white",
            fontWeight: "500",
            left: isMobile ? "8%" : "3%",
            top: "2%",
            fontSize: isMobile ? "2.5rem" : "1.5rem",
            position: "relative",
          }}
        >
          {t("your") + " " + t("cases")}
        </p>
        <Button
          style={{
            backgroundColor: "transparent",
            color: "white",
            marginTop: isMobile ? "2%" : "1%",
            position: "absolute",
            right: isMobile ? "5%" : "2%",
            width: isMobile ? "30%" : "20%",
            fontSize: isMobile ? "2.5rem" : "1.3rem",
          }}
          onClick={() => handleNavigateCasesOverview(true)}
          label={t("View all")}
        ></Button>

        <Container
          style={{ width: "80%", top: 0, marginLeft: isMobile ? "8%" : "2%" }}
          itemsPerRow={2}
          gap="0"
          margin="0"
          itemWidth="auto"
          columnWidths={["60%", "40%"]}
        >
          <CaseCard
            style={{
              backgroundColor: "white",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => handleChatClicked()}
          >
            <div
              style={{
                width: "50%",
                height: isMobile ? "5rem" : "2rem",
                borderRadius: "100px",
                right: "2%",
                top: "2%",
                position: "absolute",
                background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
              }}
            >
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  bottom: isMobile ? "10%" : "24%",
                  height: "100%",
                  fontWeight: "500",
                  fontSize: isMobile ? "2rem" : "1rem",
                }}
              >
                {t("Recommended")}
              </p>
            </div>
            <p
              style={{
                fontWeight: "500",
                fontSize: isMobile ? "1.6rem" : "1.1rem",
                marginLeft: "5%",
                position: "absolute",
                bottom: isMobile ? "6.5rem" : "3.5rem",
              }}
            >
              {t("Explain your problem")}
            </p>
            <p
              style={{
                fontWeight: "700",
                fontSize: isMobile ? "3rem" : "1.6rem",
                marginLeft: "5%",
                position: "absolute",
                bottom: "10px",
              }}
            >
              Chat
            </p>
          </CaseCard>
          {false ? (
            <CaseCard style={{ backgroundColor: "white", cursor: "pointer" }}>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.6rem",
                  marginLeft: "5%",
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                {t("Contact Attorney")}
              </p>
            </CaseCard>
          ) : undefined}
        </Container>
        <Container
          itemWidth="auto"
          gap="0"
          margin="0"
          itemsPerRow={4}
          style={{ marginLeft: "10%" }}
        >
          {cases &&
            !isMobile &&
            cases.slice(0, 3).map((input, index) => (
              <div
                key={index}
                onClick={() => handleCasesClick(index)}
                style={getSubdivStyle(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  style={{
                    width: "auto",
                    minWidth: "30%",
                    paddingLeft: "6%",
                    paddingRight: "6%",
                    borderRadius: "100px",
                    background:
                      "linear-gradient(135deg, #69ADFF 0%, #B3E5FC 100%)",
                    backgroundColor: "#69ADFF",
                    height: "2.5rem",
                    position: "absolute",
                    right: "2%",
                    top: "2%",
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      height: "100%",
                      fontWeight: "600",
                      bottom: "10%",
                    }}
                  >
                    {
                      mapStatus(input.status)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    }
                  </p>
                </div>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    left: "10%",
                    top: "15%",
                    position: "absolute",
                    textAlign: "left",
                  }}
                >
                  {input.reason
                    ? mapReason(input.reason)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    : "Problem"}
                </p>
              </div>
            ))}
        </Container>
      </Container>
      <Container
        itemWidth="auto"
        itemsPerRow={isMobile ? 1 : 2}
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        gap="0"
        margin="0"
        style={{ marginLeft: "5%", marginTop: "2%", position: "absolute" }}
      >
        <div
          style={{ display: "grid", width: "100%" }}
          onClick={() => handleContractClick()}
        >
          <img
            style={{
              width: "90%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
            src={require("./shared/assets/smartmieter_contract_visualization.png")}
          ></img>
          <p
            style={{
              fontSize: isMobile ? "3.5rem" : "2rem",
              fontWeight: "800",
              color: "white",
              marginLeft: "5%",
              position: "absolute",
              top: 0,
            }}
          >
            {t("RENTAL CONTRACT")} <br></br> {t("UPLOAD")}
          </p>
          <ContinueButton
            onClick={() => handleContractClick()}
            style={{
              position: "absolute",
              bottom: isMobile ? "5%" : "15%",
              marginLeft: "5%",
            }}
          ></ContinueButton>
        </div>

        {false ? (
          <div
            style={{
              height: "10rem",
              top: 0,
              position: "relative",
              display: "grid",
            }}
          >
            <img
              style={{
                width: "90%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
              src={require("./shared/assets/smartmieter_utility_cost_visualization.png")}
            ></img>
            <p
              style={{
                fontSize: "2rem",
                fontWeight: "800",
                position: "absolute",
                color: "black",
                marginLeft: "5%",
                top: 0,
              }}
            >
              {t("SERVICECHARGES")} <br></br> {t("UPLOAD")}
            </p>
            <ContinueButton
              style={{ position: "absolute", top: "70%", marginLeft: "5%" }}
              colorScheme="dark"
            ></ContinueButton>
          </div>
        ) : undefined}
      </Container>
      <Container
        itemWidth="auto"
        itemsPerRow={isMobile ? 2 : 4}
        columnWidths={isMobile ? ["50%", "50%"] : ["25%", "25%", "25%", "25%"]}
        gap="5px"
        style={{
          bottom: "0",
          position: "absolute",
          width: "90%",
          left: "5%",
          right: "5%",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
        }}
      >
        {isMobile
          ? iGot99ProblemsAndABitchAintOne.slice(0, 4).map((input, index) => (
              <Pill
                key={index}
                label={input}
                style={{
                  minWidth: "88%",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
                textStyle={{ fontSize: isMobile ? "1.3rem" : "0.8rem" }}
                onClick={() => handlePillClick(input)}
              />
            ))
          : iGot99ProblemsAndABitchAintOne.map((input, index) => (
              <Pill
                key={index}
                label={input}
                style={{
                  minWidth: "88%",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
                textStyle={{ fontSize: isMobile ? "1.3rem" : "0.8rem" }}
                onClick={() => handlePillClick(input)}
              />
            ))}
      </Container>
      <Account></Account>
      {casesVisible ? <BlurredBackground></BlurredBackground> : undefined}
      {correspondenceVisible ? (
        <Correspondence
          Case={Case}
          onCancel={() => handleShowCorrespondence(false)}
          onAcceptSuggestion={() => handleShowAcceptSuggestion(true)}
        ></Correspondence>
      ) : undefined}
      {acceptSuggestionSelected ? (
        <AcceptSuggestion
          onCancel={() => handleShowAcceptSuggestion(false)}
        ></AcceptSuggestion>
      ) : undefined}
      {casesVisible ? (
        <CasesMenu
          Case={Case}
          onCancel={() => setCasesVisible(false)}
          onClick={() => handleShowCorrespondence(true)}
        ></CasesMenu>
      ) : undefined}
    </div>
  );
};

export default App;
