import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "./components";
import { useAuth } from "../Auth/provider";
import { storeAnalyticsEvent, useMouseflow } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "./mobile";

export const Header = ({
  onAccountClick,
  parentRef,
  nestedRef,
  hidden = false,
  hideSignUp = false,
  staticColor = false,
}) => {
  const isMobile = useResponsiveStyles();
  const location = useLocation();
  const [feedbackHeight, setFeedbackHeight] = useState("2rem");
  const { t, i18n } = useTranslation();
  const [bgColor, setBgColor] = useState("");
  const { user } = useAuth();

  let scrollTimeout = null;
  const color = staticColor
    ? "black"
    : bgColor === "rgba(0, 0, 0, 0)"
      ? "black"
      : "white";

  let newColor;
  let parentColor;
  useEffect(() => {
    if (!parentRef || !parentRef.current) return;
    const handleScroll = () => {
      if (parentRef.current && nestedRef.current) {
        parentColor = window.getComputedStyle(
          parentRef.current
        ).backgroundColor;
        newColor = window.getComputedStyle(nestedRef.current).backgroundColor;

        const nestedRefRect = nestedRef.current.getBoundingClientRect();

        const isNestedRefVisible =
          nestedRefRect.top < window.innerHeight && nestedRefRect.bottom > 0;
        if (!isNestedRefVisible) {
          setBgColor(parentColor);
        } else {
          setBgColor(newColor);
        }
        console.log(bgColor);
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      setFeedbackHeight("2rem");
      scrollTimeout = setTimeout(() => {
        setFeedbackHeight("2rem");
      }, 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [parentRef, nestedRef]);

  const headerStyles = {
    position: "fixed",
    backgroundColor:
      bgColor === "rgba(0, 0, 0, 0)"
        ? "rgba(255,255,255, 0.4)"
        : "rgba(255,255,255, 0)",
    width: "100%",
    zIndex: 20,
    backdropFilter: "blur(10px)",
    webkitBackdropFilter: "blur(10px)",
    borderBottom: bgColor === "rgba(0, 0, 0, 0)" ? "0.5px solid lightgray" : "",
    top: 0,
    margin: 0,
    padding: 0,
  };

  const link = {
    de: "https://forms.gle/Hq4h4pxbwDMC6XbR7",
    en: "https://forms.gle/9cDUxTSaisdzgqej6",
  }[i18n.language];

  const handleFeedbackClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label: "Feedback",
    });

    window.location.href = link;
  };

  useMouseflow();

  return (
    <header style={headerStyles}>
      <Container
        itemsPerRow={3}
        columnWidths={isMobile ? ["50%", "0%", "50%"] : ["25%", "50%", "25%"]}
        itemWidth={"100%"}
        alignItems="auto"
        style={{
          width: "100%",
          top: 0,
          margin: 0,
          padding: 0,
        }}
      >
        {/**TODO: remove style for LogoButton with SNM-31 */}

        <LogoButton color={color} isMobile={isMobile} />
        {!isMobile && !hidden ? (
          <Container
            itemsPerRow={5}
            itemWidth="auto"
            style={{ width: "100%", display: "flex", marginTop: "2.5%" }}
          >
            <HeaderButton url={user ? "/contract" : "/alpha"} color={color}>
              {/*ALPHA: CHANGE URL TO FUNNEL OR REGISTER*/}
              {user ? t("shared-header.contracts") : t("shared-header.gethelp")}
            </HeaderButton>
            <HeaderButton url={user ? "/chat" : "/"} color={color}>
              {" "}
              {/** ADD MARKETING PAGE FOR CONTRACTS */}
              {user ? t("shared-header.chat") : t("shared-header.contracts")}
            </HeaderButton>
            <HeaderButton url={user ? "/cases" : "/"} color={color}>
              {" "}
              {/** ADD MARKETING PAGE FOR SERVICECHARGES */}
              {user
                ? t("shared-header.cases")
                : t("shared-header.servicecharges")}
            </HeaderButton>
            <HeaderButton url={user ? "/cases" : "/about"} color={color}>
              {user
                ? t("shared-header.servicecharges")
                : t("shared-header.aboutus")}
            </HeaderButton>
          </Container>
        ) : undefined}
        {user?.beta ? (
          <div
            onClick={handleFeedbackClick}
            style={{
              position: "fixed",
              right: "2%",
              top: isMobile ? "3%" : "2%",
              width: isMobile ? "23rem" : "10rem",
              height: isMobile ? "8rem" : feedbackHeight,
              backgroundColor: "black",
              borderRadius: isMobile ? "30px" : "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={() => setFeedbackHeight("10rem")}
            onMouseLeave={() => setFeedbackHeight("2rem")}
          >
            <p
              style={{
                zIndex: 10,
                color: "white",
                fontWeight: 600,
                textAlign: "center",
                fontSize: isMobile ? "2.5rem" : "1rem",
              }}
            >
              {t("feedback")}
            </p>
            {/* {!isMobile ? (
              <img
                style={{
                  opacity: feedbackHeight === "10rem" ? 1 : 0,
                  zIndex: 10,
                  width: "50%",
                  left: "25%",
                  transform: "translateX(50%)",
                }}
                src={require("./assets/smartmieter_feedback_icon.png")}
              ></img>
            ) : undefined} */}
          </div>
        ) : !hideSignUp ? (
          // BETA CHANGE BUTTON LABEL
          <SignUpButton
            color={color}
            loggedIn={user}
            isMobile={isMobile}
            label={user ? t("shared-header.account") : t("shared-header.alpha")}
          ></SignUpButton>
        ) : undefined}
      </Container>
    </header>
  );
};

const HeaderButton = ({ url, children, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        border: "none",
        appearance: "none",
        backgroundColor: "transparent",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        fontWeight: 600,
        color,
      }}
    >
      {children}
    </button>
  );
};

const LogoButton = ({ style, url, color, isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    if (!user || !user.accountStatus === "inactive") {
      navigate("/");
    } else {
      navigate("/app");
    }
    storeAnalyticsEvent({
      category: "/home",
      action: "clicked",
      label: "Logo Clicked",
    });
  };

  const desktopStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    marginLeft: "10%",
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const mobileStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 900,
    fontSize: "10rem",
    fontStyle: "italic",
    textAlign: "left",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: isMobile ? 0 : "",
    marginLeft: "10%",
    marginTop: isMobile ? "0" : "3%",
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        color,
        ...(isMobile ? mobileStyle : desktopStyle),
        ...style,
      }}
    >
      {isMobile ? "s" : "smartmieter"}
    </button>
  );
};

const SignUpButton = ({ loggedIn, label, color, isMobile }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAccountOpen = searchParams.get("account") === "true";

  const handleClick = () => {
    const newParams = new URLSearchParams(searchParams);
    if (isAccountOpen) {
      newParams.delete("account");
    } else {
      newParams.set("account", "true");
    }
    setSearchParams(newParams);
  };

  const computedStyles = {
    white: { color: "black", backgroundColor: "white" },
    black: { color: "white", backgroundColor: "#69ADFF" },
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        width: isMobile ? "25rem" : "12rem",
        height: isMobile ? "6rem" : "3rem",
        padding: "10px 20px",
        fontSize: isMobile
          ? loggedIn
            ? "2.5rem"
            : "3rem"
          : loggedIn
            ? "1rem"
            : "1.5rem",
        backgroundColor: "69ADFF",
        border: "none",
        fontStyle: loggedIn ? "" : "italic",
        fontWeight: 600,
        borderRadius: "100px",
        cursor: "pointer",
        marginTop: "2%",
        marginLeft: isMobile ? "0%" : "20%",
        ...computedStyles[color],
        background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
      }}
    >
      {label}
    </button>
  );
};
