import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { Account } from "../../Account";

export const Terms = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  useAnalytics();
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: isMobile ? "5rem" : "3rem",
            fontWeight: 900,
            marginTop: isMobile ? "50%" : "25%",
          }}
        >
          {t("legal.terms-index.title1")}
        </h1>
        <p
          style={{
            color: "#AAA6A6",
            width: isMobile ? "80%" : "50%",
            fontSize: isMobile ? "3rem" : "1rem",
            marginLeft: isMobile ? "10%" : "25%",
          }}
        >
          {t("legal.terms-index.text1")}
        </p>
        <AGB></AGB>
      </Container>
      <Footer></Footer>
      <Account></Account>
    </div>
  );
};

const Sectiontitle = ({ children }) => {
  const isMobile = useResponsiveStyles();

  return (
    <h3
      style={{
        fontSize: isMobile ? "4rem !important" : "2rem",
        margin: "1em 0",
        fontWeight: "bold",
      }}
    >
      {children}
    </h3>
  );
};

const AGB = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  return (
    <div
      style={{
        width: isMobile ? "80%" : "50%",
        marginLeft: isMobile ? "10%" : "25%",
        marginTop: "20%",
        marginBottom: "10%",
        fontSize: isMobile ? "2.5rem" : "1rem",
      }}
    >
      <Sectiontitle>{t("legal.terms-index.subtitle1")}</Sectiontitle>
      <p>{t("legal.terms-index.text2")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle2")}</Sectiontitle>
      <p>{t("legal.terms-index.text3")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle3")}</Sectiontitle>
      <p>{t("legal.terms-index.text4")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle4")}</Sectiontitle>
      <p>{t("legal.terms-index.text5")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle5")}</Sectiontitle>
      <p>
        {t("legal.terms-index.text6")}
        <br />
        {t("legal.terms-index.text7")}
        <br />
        {t("legal.terms-index.text8")}
      </p>

      <Sectiontitle>{t("legal.terms-index.subtitle6")}</Sectiontitle>
      <p>{t("legal.terms-index.text9")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle7")}</Sectiontitle>
      <p>{t("legal.terms-index.text10")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle8")}</Sectiontitle>
      <p>
        {t("legal.terms-index.text11")}
        <a href="/privacy" style={{ color: "black" }}>
          {t("legal.terms-index.title2")}
        </a>
        .
      </p>

      <Sectiontitle>{t("legal.terms-index.subtitle9")}</Sectiontitle>
      <p>{t("legal.terms-index.text12")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle10")}</Sectiontitle>
      <p>{t("legal.terms-index.text13")}</p>

      <Sectiontitle>{t("legal.terms-index.subtitle11")}</Sectiontitle>
      <p>{t("legal.terms-index.text14")}</p>

      <p>{"Stand/Status: December 10, 2024"}</p>
    </div>
  );
};

export default AGB;
