import { useTranslation } from "react-i18next";

const getStepAsset = (step, currentStep) => {
  if (step < currentStep) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{
            width: "1.5rem",
            height: "1.5rem",
          }}
          src={require("../assets/white-check.png")}
        ></img>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <p style={{ fontWeight: 500 }}>{step}</p>
      </div>
    );
  }
};

export const Step = ({ step, currentStep }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "30% 70%",
        alignItems: "center",
        width: "95%",
        border: step === currentStep ? "3px solid #69ADFF" : "",
        borderRadius: "100px",
      }}
    >
      <div
        style={{
          backgroundColor: step === currentStep ? "#69ADFF" : "#AAD0FF",
          width: "2.2rem",
          marginLeft: "20%",
          height: "2.2rem",
          borderRadius: "100%",
        }}
      >
        {getStepAsset(step, currentStep)}
      </div>
      <p
        style={{
          height: "2.2rem",
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
      >
        {t(`cases-send.step_${step}`)}
      </p>
    </div>
  );
};
