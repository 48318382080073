import styled from "styled-components";
import { caseStatus } from "../shared/models";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useResponsiveStyles } from "../shared/mobile";

export const NewsCard = ({ title, date, href, index }) => {
  const assets = [
    "dollar-dollar-color.png",
    "euro-dynamic-color.png",
    "pound-dynamic-color.png",
    "rupee-dynamic-color.png",
    "yuan-dynamic-color.png",
  ];

  const styles = {
    backgroundColor: "#F7F7F7",
    height: "13rem",
    width: "19rem",
    borderRadius: "10px",
    marginLeft: "2%",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  };

  const titleStyles = {
    fontWeight: "500",
    fontSize: "1rem",
    textAlign: "left",
    marginLeft: "5%",
    marginTop: "40%",
    position: "absolute",
  };

  const imageStyles = {
    width: "30%",
    position: "absolute",
    bottom: "0",
    right: "0",
    borderRadius: "10px",
  };

  const BlurredBackgroundStyle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
  `;

  return (
    <div style={styles}>
      <img src={require(`./assets/${assets[index]}`)} style={imageStyles}></img>
      <BlurredBackgroundStyle></BlurredBackgroundStyle>
      <p style={titleStyles}>{title}</p>
    </div>
  );
};

export const CaseCard = ({
  title,
  status,
  caseId,
  showStatus,
  style,
  onClick,
  children,
}) => {
  const isMobile = useResponsiveStyles();

  const styles = {
    backgroundColor: "#32006B",
    height: isMobile ? "26rem" : "13rem",
    width: isMobile ? "38rem" : "19rem",
    borderRadius: isMobile ? "35px" : "10px",
    marginLeft: "2%",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    ...style,
  };

  const statusCardStyles = {
    backgroundColor: "#F2F2F2",
    minWidth: "60%",
    maxWidth: "80%",
    height: "20%",
    borderRadius: "5px",
    marginTop: "2%",
    marginRight: "2%",
    float: "right",
    color: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const titleStyles = {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: "700",
    textAlign: "left",
    marginLeft: "5%",
    position: "absolute",
    marginTop: "30%",
  };

  return (
    <div style={styles} onClick={onClick}>
      {showStatus ? (
        <div style={statusCardStyles}>
          <p>{caseStatus[status]}</p>
        </div>
      ) : undefined}
      <p style={titleStyles}>{title}</p>
      {React.Children.map(children, (child) => (
        <div>{child}</div>
      ))}
    </div>
  );
};

export const Pill = ({ label, style, textStyle, onClick }) => {
  const isMobile = useResponsiveStyles();

  return (
    <div
      style={{
        width: isMobile ? "85%" : "auto",
        minWidth: "35%",
        paddingLeft: "6%",
        paddingRight: "6%",
        height: isMobile ? "4rem" : "2.2rem",
        borderRadius: "100px",
        right: "2%",
        top: "2%",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "1rem",
        marginRight: "1rem",
        background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
        ...style,
      }}
      onClick={onClick}
    >
      <p
        style={{
          width: "100%",
          padding: "3px",
          textAlign: "center",
          fontSize: isMobile ? "2rem" : "1rem",
          fontWeight: "500",
          ...textStyle,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export const DocumentUpload = ({ onFileChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    onFileChange(file);
  };

  const handleSelectDocument = () => {
    fileInputRef.current?.click();
  };

  const imageSrc = {
    select: require("./assets/smartmieter_plus_icon.png"),
    selected: require("../shared/assets/confirmation.png"),
  };

  return (
    <div style={{ height: "7.5rem" }}>
      <div
        onClick={handleSelectDocument}
        style={{
          width: "12rem",
          height: "6rem",
          borderRadius: "10px",
          border: "1px solid #AAA6A6",
          cursor: "pointer",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={(event) => {
            handleFileChange(event);
          }}
          style={{
            display: "none",
            height: "100%",
            width: "100%",
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            style={{ width: selectedFile ? "15%" : "20%" }}
            src={selectedFile ? imageSrc["selected"] : imageSrc["select"]}
          ></img>
        </div>
      </div>
      {selectedFile && (
        <p style={{ textAlign: "left", fontSize: "0.8rem" }}>
          {selectedFile.name}
        </p>
      )}
    </div>
  );
};
