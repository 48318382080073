import { storeAnalyticsEvent, useAnalytics } from "../../../analytics";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const Partners = ({ onClick }) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const backgroundStyle = {
    width: "100%",
    height: isMobile ? "60vh" : "100vh",
    marginTop: isMobile ? "40%" : "20%" /*40%*/,
    marginBotttom: isMobile ? "auto" : 0,
    position: "relative",
  };

  const titleStyles = {
    fontSize: isMobile ? "4rem" : "3rem",
    fontWeight: 900,
    color: "white",
  };

  const subtitleStyles = {
    fontSize: isMobile ? "2.5rem" : "1.5rem",
    top: 0,
    color: "white",
  };

  const buttonStyles = {
    backgroundColor: "transparent",
    width: isMobile ? "30rem" : "15rem",
    height: "3rem",
    cursor: "pointer",
    fontSize: isMobile ? "3rem" : "1.1rem",
    borderRadius: "100px",
    fontWeight: 800,
    textAlign: "left",
    border: "none",
    color: "white",
  };

  useAnalytics();

  const handleButtonClick = () => {
    storeAnalyticsEvent({
      category: "/home:partners",
      action: "clicked",
      label: "Lets go",
    });
    onClick();
  };

  const imageSrc = {
    mobile: require("../assets/smartmieter_lawyer_mobile.png"),
    desktop: require("../assets/smartmieter_lawyer_image.png"),
  };

  return (
    <div style={backgroundStyle}>
      <img
        style={{
          left: 0,
          bottom: 0,
          top: 0,
          right: 0,
          width: "100%",
          position: "absolute",
        }}
        src={isMobile ? imageSrc.mobile : imageSrc.desktop}
        alt="Lawyer representing our partner attorney firms"
      ></img>
      <div
        style={{
          width: "80%",
          marginTop: isMobile ? "15%" : "45%",
          position: "absolute",
          marginLeft: "10%",
          zIndex: 10,
        }}
      >
        <p style={titleStyles}>{t("home.comp-partners.text1")}</p>
        <p style={subtitleStyles}>{t("home.comp-partners.text2")}</p>
        <button onClick={handleButtonClick} style={buttonStyles}>
          {t("home.comp-partners.text3")}
        </button>
      </div>
    </div>
  );
};
