import { useAnalytics } from "../../../analytics";
import { Container } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const EasyHelp = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();

  const titleStyles = {
    fontSize: isMobile ? "5rem" : "2.5rem" /**/,
    fontWeight: 800,
  };

  const textStyle = {
    fontSize: isMobile ? "3rem" : "1.2rem",
    fontWeight: 600,
    marginTop: "7%",
    color: "#AAA6A6",
    width: isMobile ? "85%" : "70%",
  };

  console.log(`language: ${i18n.language}`);
  useAnalytics();

  return (
    <div>
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="fit-content"
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        style={{
          marginLeft: isMobile ? "10%" : "5%",
          height: isMobile ? "auto" : "50vh",
          marginTop: isMobile ? "30%" : 0,
          position: "relative",
        }}
      >
        <div>
          <p style={titleStyles}>
            {t("home.comp-easyhelp.help")} <br></br>
            {t("home.comp-easyhelp.solve")}
          </p>
          <p style={textStyle}>
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.know")}
            </span>{" "}
            {t("home.comp-easyhelp.better")}
          </p>
          <p style={textStyle}>
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.stress")}
            </span>{" "}
            {t("home.comp-easyhelp.text1")}
          </p>
          <p style={textStyle}>
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.247")}
            </span>{" "}
            {t("home.comp-easyhelp.comp")}
            <span style={{ color: "black" }}>
              {" "}
              {t("home.comp-easyhelp.start")}
            </span>
          </p>
        </div>
        {!isMobile ? (
          <img
            style={{ marginLeft: "5%", width: "75%", marginTop: "5%" }}
            src={require(
              `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_product_display.png`
            )}
          ></img>
        ) : undefined}
      </Container>
    </div>
  );
};
