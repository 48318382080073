import { useAnalytics } from "../analytics";
import { Header } from "../shared/header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getStageConfig } from "../config/config";
import { useEffect, useState } from "react";
import { Container, SelectionInput, Input, Button } from "../shared/components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import { useLocation } from "react-router-dom";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";

export const Alpha = () => {
  const isMobile = useResponsiveStyles();
  const { t } = useTranslation();
  const config = getStageConfig();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("mrs");
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const [language, setLanguage] = useState("");
  const [error, setError] = useState("");
  const { signIn } = useAuth();
  const [validInput, setValidInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d\w\W]{8,}$/;
  const validatePassword = () => passwordRegex.test(password);

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);
  {
    /**erklären, wie man Feedback abgeben kann*/
  }

  const checkInput = () => {
    if (name.length === 0 || password.length === 0 || email.length === 0) {
      setError(t("marketing-alpha.text1"));
      return false;
    } else if (!validatePassword()) {
      setError(t("marketing-alpha.text2"));
      return false;
    }
    setValidInput(true);
    setError("");
    return true;
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    checkInput();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkInput();
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    checkInput();
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    checkInput();
  };

  const createUser = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post(`${config.API_URL}/default/users`, {
        fullName: name,
        email,
        address,
        language,
        password,
        city: "random",
        beta: true,
      });

      if (result.status === 409) {
        setError(t("errors.email_exists"));
      }

      // BETA TODO: we changed some flags of "beta" to "alpha" but not all. The beta prop inside of user is also set to true for alpha users

      if (result.data && result.data.tenantId) {
        const newTenantId = result.data.tenantId;

        localStorage.setItem("funnel_tenant_id", newTenantId);

        try {
          const authResult = await signIn(email, password);
          if (authResult.redirect) {
            navigate("/app");
          }
        } catch (error) {
          setError(error.type);
        }
      } else {
        console.error("Error creating user:", result);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      setIsLoading(false); // Stop loading after the request finishes
    }
  };

  const image = {
    mobile: require("./assets/smartmieter_retro_background_mobile.png"),
    desktop: require("./assets/smartmieter_retro_background.png"),
  };

  useAnalytics();
  return (
    <div>
      <Header staticColor={false} hidden={true} hideSignUp={true}></Header>
      <Container
        itemWidth="100%"
        margin="0"
        gap="0"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <p
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: "3rem",
            position: "fixed",
            marginLeft: "3%",
            marginTop: isMobile ? "20%" : "5%",
            zIndex: 10,
          }}
        >
          {t("alpha.title")}
        </p>
        <div
          style={{
            position: "fixed", // Change to 'absolute' to center within a parent
            zIndex: 10,
            backgroundColor: "white",
            height: isMobile ? "70rem" : "38rem",
            width: "50rem",
            top: isMobile ? "55%" : "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: isMobile ? "30px" : "10px",
          }}
        >
          <Container
            itemsPerRow={1}
            itemWidth="auto"
            gap="0"
            style={{
              marginTop: "2%",
              width: "100%",
              marginLeft: isMobile ? "15%" : "25%",
            }}
            columnWidths={["100%"]}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1rem",
                bottom: 0,
                margin: 0,
                marginTop: isMobile ? "6%" : "3%",
              }}
            >
              {t("common.address")}
            </p>
            <SelectionInput
              onChange={(event) => handleAddressChange(event)}
              style={{
                width: isMobile ? "70%" : "50%",
                height: isMobile ? "5rem" : "3rem",
                fontWeight: 500,
                marginTop: "1%",
                border: "1px solid lightgray",
              }}
              options={[
                { label: t("common.female"), value: "ms" },
                { label: t("common.male"), value: "mr" },
                { label: t("common.other"), value: "other" },
              ]}
            ></SelectionInput>
            <p
              style={{
                fontWeight: 500,
                margin: 0,
                marginTop: isMobile ? "6%" : "4%",
                fontSize: isMobile ? "2rem" : "1rem",
              }}
            >
              {t("common.fullname")}
            </p>
            <Input
              onEnter={() => {}}
              style={{
                width: isMobile ? "70%" : "50%",
                fontWeight: 500,
                marginTop: "1%",
                border: "1px solid lightgray",
              }}
              placeholder={t("components.enter.name")}
              value={name}
              onChange={(event) => handleNameChange(event)}
            ></Input>

            <p
              style={{
                fontWeight: 500,
                margin: 0,
                marginTop: isMobile ? "6%" : "4%",
                fontSize: isMobile ? "2rem" : "1rem",
              }}
            >
              {t("common.email")}
            </p>
            <Input
              onEnter={() => {}}
              style={{
                width: isMobile ? "70%" : "50%",
                fontWeight: 500,
                marginTop: "1%",
                border: "1px solid lightgray",
              }}
              placeholder="email@example.com"
              value={email}
              onChange={(event) => handleEmailChange(event)}
            ></Input>

            <p
              style={{
                fontWeight: 500,
                bottom: 0,
                margin: 0,
                marginTop: isMobile ? "6%" : "4%",
                fontSize: isMobile ? "2rem" : "1rem",
              }}
            >
              {t("common.password")}
            </p>
            <Input
              onEnter={() => {}}
              style={{
                width: isMobile ? "70%" : "50%",
                fontWeight: 500,
                marginTop: "1%",
                border: "1px solid lightgray",
              }}
              placeholder={t("components.choose.password")}
              type="password"
              onChange={(event) => handlePasswordChange(event)}
            ></Input>
            <p
              style={{
                fontSize: isMobile ? "1.3rem" : "0.6rem",
                width: isMobile ? "70%" : "60%",
              }}
            >
              {t("alpha.password_info")}
            </p>
            <p
              style={{
                bottom: 0,
                fontSize: "0.8rem",
                marginTop: "1%",
                width: "50%",
              }}
            >
              {error}
            </p>
            <Button
              style={{
                backgroundColor: "#69ADFF",
                fontWeight: 600,
                width: isMobile ? "70%" : "50%",
                height: isMobile ? "5rem" : "3.5rem",
              }}
              active={validInput}
              onClick={createUser}
              disabled={isLoading}
              label={t("common.continue")}
            />

            <Button
              onClick={() => navigate("/login")}
              label={t("alpha.login")}
              style={{
                backgroundColor: "transparent",
                color: "black",
                width: isMobile ? "70%" : "50%",
                fontSize: isMobile ? "1.3rem" : "0.8rem",
              }}
            ></Button>
          </Container>
        </div>
        <p
          style={{
            color: "white",
            fontSize: "1rem",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "58%",
            zIndex: 10,
          }}
        >
          {t("alpha.helpus")}
        </p>
        <img
          src={isMobile ? image.mobile : image.desktop}
          style={{ width: "100%", position: "relative" }}
        ></img>
      </Container>
    </div>
  );
};
