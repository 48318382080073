import { useAnalytics } from "../../analytics";
import { getStageConfig } from "../../config/config";
import { Button, TextArea } from "../../shared/components";
import { Container } from "../../shared/components";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Problem = ({ onClick, resolve, reject }) => {
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 1);
  const config = getStageConfig();
  const [description, setDescription] = useState("");

  useAnalytics();

  const getProblemEvaluation = async () => {
    try {
      const result = await axios.post(
        `${config.API_URL}/default/compensation`,
        { description }
      );

      if (result.status === 200) {
        console.log(`result data: ` + JSON.stringify(result.data));
        resolve(result.data);
      } else {
        console.error(`result status: ${JSON.stringify(result)}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinueClick = async () => {
    await getProblemEvaluation();
    localStorage.setItem("funnel_prompt", description);
    onClick();
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    localStorage.setItem("funnel_prompt", event.target.value);
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <p style={{ fontWeight: 700, fontSize: "1.1rem" }}>
          {t("signup.comp-problem.text1")}
        </p>
        <TextArea
          onEnter={() => {}}
          value={localStorage.getItem("funnel_prompt")}
          onChange={handleDescriptionChange}
          style={{ width: "70%", height: "20rem" }}
          placeholder={t("signup.comp-problem.text2")}
        ></TextArea>
        <p style={{ fontSize: "0.8rem" }}>{t("signup.comp-problem.text3")}</p>
        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: "25%",
            height: "3.5rem",
          }}
          active={description.length > 15}
          onClick={handleContinueClick}
          label={t("signup.comp-problem.continue")}
        ></Button>
      </Container>
    </div>
  );
};
