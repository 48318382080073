import { getStageConfig } from "../config/config";
import { CancelButton, Container } from "../shared/components";
import { DarkBlurredBackground } from "../shared/components";
import { useEffect, useState } from "react";
import axios from "axios";
import { getDateByISO, mapReason } from "../shared/helpers";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { Dialog } from "../shared/dialog";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { CorrespondenceCard } from "./components/card";
import { CorrespondenceDetails, MobileDetails } from "./components/details";

export const Correspondence = ({ Case, onCancel, onAcceptSuggestion }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();

  const config = getStageConfig();
  const { user, jwtToken } = useAuth();
  const [index, setIndex] = useState(0);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [correspondence, setCorrespondences] = useState([]);

  const backgroundStyle = {
    width: "90vw",
    height: isMobile ? "75vh" : "85vh",
    borderRadius: "20px",
    backgroundColor: "white",
    zIndex: 10,
    position: "fixed",
    top: isMobile ? "15%" : "10%",
    left: "5%",
  };

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  useEffect(() => {
    console.log(`Case in correspondence: ${JSON.stringify(Case)}`);
    const fetchData = async () => {
      let result;
      try {
        result = await axios.get(
          `${config.API_URL}/default/correspondence/${Case.caseId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        
        setCorrespondences(result.data ?? []);
      } catch (error) {
        setDialogError(true);
      }
    };

    fetchData();
  }, []);

  useAnalytics();

  const handleButtonClick = () => {
    if (!correspondence || correspondence.length === 0) {
      console.error("Correspondence is null, empty, or index is out of bounds");
      setDialogError(true);
      setDialogMessage(t("cases-corr.empty"));
      return;
    }

    switch (correspondence[index].from) {
      case t("cases-corr.landlord"):
      case t("cases-corr.management"):
        onAcceptSuggestion();
        break;
      case t("cases-corr.renter"):
        handleResolveCase();
        break;
      case t("cases-corr.attorney"):
        //handleAttorneyChat()
        break;
    }
  };

  const handleResolveCase = async () => {
    const result = await axios.put(
      `${config.API_URL}/default/cases/${user?.tenantId}?caseId=${Case.caseId}&status=resolved`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    if (result.status === 200) {
      // TODO: Case resolved. Ask for feedback on case and why it was resolved ("received payment", "mediated", "no longer interested")

      setDialogSuccess(true);
      setDialogTitle(t("cases-corr.thanks"));
      setDialogMessage(t("cases-corr.solved"));
      setTimeout(onCancel, 4000);
    }
  };

  const handleRowClick = (index) => {
    if (isMobile) {
      setDetailsVisible(true);
    }
    setIndex(index);
  };

  return (
    <div>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={backgroundStyle}>
        <Container
          style={{ width: "100%" }}
          itemsPerRow={3}
          itemWidth="auto"
          columnWidths={isMobile ? ["75%", "0%", "25%"] : ["60%", "30%", "5%"]}
        >
          <p
            style={{
              fontWeight: 600,
              fontSize: isMobile ? "3.5rem" : "2rem",
              marginTop: isMobile ? "5%" : "3%",
              marginLeft: isMobile ? "8%" : "3%",
            }}
          >
            {t("cases-corr.messages")}
            {mapReason(Case.reason)[user?.language.substring(0, 2) ?? "de"]}
          </p>
          {isMobile ? undefined : (
            <p style={{ color: "#868080", textAlign: "right", margin: 0 }}>
              {t("cases-corr.opened")}
              {getDateByISO(Case.createdAt)}
            </p>
          )}
          <CancelButton onClick={onCancel}></CancelButton>
        </Container>
        {isMobile ? (
          <p
            style={{
              color: "#868080",
              textAlign: "left",
              margin: 0,
              fontSize: isMobile ? "2rem" : "1rem",
              marginLeft: isMobile ? "8%" : "",
            }}
          >
            {t("cases-corr.opened")}
            {getDateByISO(Case.createdAt)}
          </p>
        ) : undefined}
        <Container
          style={{ width: "100%", marginTop: "3%" }}
          itemsPerRow={isMobile ? 1 : 2}
          alignItems="auto"
          itemWidth="auto"
          margin="0"
          columnWidths={["40%", "60%"]}
        >
          <div
            style={{
              marginLeft: isMobile ? "8%" : "5%",
              width: isMobile ? "79vw" : "40vw",
              height: isMobile ? "57vh" : "75vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {correspondence.map((item, index) => (
              <CorrespondenceCard
                index={index}
                type={item?.from}
                date={getDateByISO(item?.sentAt)}
                onClick={(index) => handleRowClick(index)}
                offer={item?.offer}
              ></CorrespondenceCard>
            ))}
          </div>

          {isMobile ? undefined : (
            <CorrespondenceDetails
              sender={correspondence[index]?.from}
              message={correspondence[index]?.message}
              offer={correspondence[index]?.offer}
              date={correspondence[index]?.date}
            ></CorrespondenceDetails>
          )}
        </Container>
      </div>
      {detailsVisible ? (
        <MobileDetails
          sender={correspondence[index]?.from}
          date={correspondence[index]?.date}
          message={correspondence[index]?.message}
          offer={correspondence[index]?.offer}
          onClick={handleButtonClick}
          onCancel={onCancel}
        ></MobileDetails>
      ) : undefined}
      {dialogError ? (
        <Dialog
          type="error"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
      {dialogSuccess ? (
        <Dialog
          type="success"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
    </div>
  );
};
