import { Button } from "../../shared/components";
import { Container } from "../../shared/components";
import { DocumentUpload, Pill } from "../../App/components";
import axios from "axios";
import { useState } from "react";
import { getStageConfig } from "../../config/config";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Documents = ({ onClick }) => {
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 5);

  const [pdfFiles, setPdfFiles] = useState([]);
  const config = getStageConfig();

  useAnalytics();

  const onFileChange = (index, file) => {
    if (file) {
      const newPdfFiles = [...pdfFiles];
      newPdfFiles[index] = file;
      setPdfFiles((prevPdfFiles) => {
        const updatedPdfFiles = [...prevPdfFiles];
        updatedPdfFiles[index] = file;
        return updatedPdfFiles;
      });
    }
  };

  const tenantId = localStorage.getItem("funnel_tenant_id");

  const uploadImages = (presignedUrls) => {
    return pdfFiles.map((file, index) =>
      axios.put(presignedUrls[index].presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
        },
      })
    );
  };

  const getSignedUrl = async () => {
    const images = [];
    pdfFiles.forEach((file) => {
      if (file) {
        images.push({ title: file.name, contentType: file.type });
      }
    });

    console.log(`images: ${JSON.stringify(images)}`);
    try {
      const result = await axios.post(
        `${config.API_URL}/default/compensation/images/${tenantId}`,
        { images },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (result.status === 200) {
        await Promise.all(uploadImages(result.data));
      }
    } catch (error) {
      console.error(`error occured, while uploading images: ${error}`);
    }
  };

  const continueClicked = async () => {
    await getSignedUrl();
    onClick();
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%", width: "100%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <p
          style={{ fontWeight: 700, fontSize: "1.1rem", bottom: 0, margin: 0 }}
        >
          {t("signup.comp-docs.text1")}
        </p>

        <p>{t("signup.comp-docs.text2")}</p>
        <Container itemsPerRow={4} margin="0" gap="0" style={{ width: "80%" }}>
          <DocumentUpload
            onFileChange={(file) => onFileChange(0, file)}
          ></DocumentUpload>
          <DocumentUpload
            onFileChange={(file) => onFileChange(1, file)}
          ></DocumentUpload>
          <DocumentUpload
            onFileChange={(file) => onFileChange(2, file)}
          ></DocumentUpload>
          <DocumentUpload
            onFileChange={(file) => onFileChange(3, file)}
          ></DocumentUpload>
        </Container>

        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: "25%",
            height: "3.5rem",
            marginTop: "2%",
          }}
          onClick={continueClicked}
          label={t("signup.comp-docs.text3")}
        ></Button>

        <div style={{ position: "absolute", bottom: "10%" }}>
          <img
            style={{
              width: "90%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
            src={require("../assets/smartmieter_instant_payments_background.png")}
            alt="background for instant payments"
          ></img>

          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "3%",
            }}
          >
            <Container
              itemsPerRow={2}
              margin="0"
              gap="0"
              columnWidths={["73%", "27%"]}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: "2rem",
                  margin: 0,
                }}
              >
                {t("signup.comp-docs.text4")}
              </p>
              <Pill
                textStyle={{ top: "5%", whiteSpace: "nowrap", color: "white" }}
                style={{ width: "40%" }}
                label={t("signup.comp-docs.text5")}
              ></Pill>
            </Container>

            <p style={{ color: "white", width: "70%", marginTop: "1%" }}>
              {t("signup.comp-docs.text6")}
            </p>
            <p style={{ color: "white", fontSize: "0.5rem", marginTop: 0 }}>
              {t("signup.comp-docs.text7")}
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};
