import { useAnalytics } from "../../../analytics";
import { Container } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const How = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();
  useAnalytics();

  return (
    <div
      style={{
        height: isMobile ? "auto" : "200vh" /*300rem*/,
        marginTop: isMobile
          ? window.innerHeight <= 2000
            ? "20rem"
            : "-20rem"
          : "10rem",
        marginBottom: isMobile ? "10rem" : 0,
      }}
    >
      <Container
        margin="0"
        itemWidth="fit-content"
        itemsPerRow={1}
        columnWidths={["100%"]}
        style={{ marginLeft: isMobile ? "10%" : "5%", width: "100%" }}
      >
        <p
          style={{
            fontWeight: 800,
            fontSize: isMobile ? "5rem" : "3rem",
            whiteSpace: isMobile ? "" : "nowrap",
            width: isMobile ? "70%" : "auto",
            marginBottom: 0,
          }}
        >
          {t("home.comp-how.title")}
        </p>
        <p
          style={{
            fontSize: isMobile ? "2.5rem" : "1.2rem",
            fontWeight: 500,
            width: isMobile ? "70%" : "50%",
            top: 0,
          }}
        >
          {t("home.comp-how.subtitle")}
          {t("home.comp-how.text1")}
          {t("home.comp-how.text2")}
        </p>
        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={isMobile ? 1 : 2}
          columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
          style={{ marginTop: "5%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: isMobile ? "60rem" : "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_reasons.png`
              )}
              alt="Description of how to reasons in respective language"
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "3rem" : "1.5rem",
                width: "80%",
                marginTop: "2%",
                top: 0,
                marginBottom: 0,
              }}
            >
              {t("home.comp-how.text3")}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1.2rem",
                width: "80%",
              }}
            >
              {t("home.comp-how.text4")}
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{
              marginTop: isMobile ? "20%" : 0,
              height: isMobile ? "60rem" : "20rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_steps.png`
              )}
              alt="Description of how to steps in respective language"
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "3rem" : "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              {t("home.comp-how.text5")}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1.2rem",
                width: "80%",
              }}
            >
              {t("home.comp-how.text6")}
            </p>
          </Container>
        </Container>

        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={isMobile ? 1 : 2}
          columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
          style={{ marginTop: "20%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: isMobile ? "45rem" : "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_you_decide.png`
              )}
              alt="Description of how to decide in respective language"
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "3rem" : "1.5rem",
                width: "80%",
                marginTop: "2%",
                marginBottom: 0,
              }}
            >
              {t("home.comp-how.text7")}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1.2rem",
                width: "80%",
              }}
            >
              {t("home.comp-how.text8")}
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{
              marginTop: isMobile ? "10%" : 0,
              height: isMobile ? "45rem" : "20rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_result.png`
              )}
              alt="Description of how to get result in respective language"
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "3rem" : "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              {t("home.comp-how.text9")}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: isMobile ? "2rem" : "1.2rem",
                width: "80%",
              }}
            >
              {t("home.comp-how.text10")}
            </p>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
