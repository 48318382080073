import {
  DarkBlurredBackground,
  CancelButton,
  Container,
  Button,
  Input,
  SelectionInput,
} from "../shared/components";
import axios from "axios";
import { getStageConfig } from "../config/config";
import { useEffect, useState } from "react";
import { insertName, mapReason } from "../shared/helpers";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { Dialog } from "../shared/dialog";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { createMessage } from "./helpers.ts";
import { Step } from "./components/steps.jsx";
import { useNavigate } from "react-router-dom";
import { reasonTranslations } from "../shared/translations.js";

export const Send = ({
  options,
  landlordInformation,
  reason,
  caseId,
  onCancel,
}) => {
  const isMobile = useResponsiveStyles();
  const { t, i18n } = useTranslation();

  const config = getStageConfig();
  const { user, jwtToken } = useAuth();
  const [continueClicked, setContinueClicked] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState(
    "vermieter@mails.smartmieter.com"
  );
  const [contactAddress, setContactAddress] = useState("mrs");
  const [index, setIndex] = useState(0);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [message, setMessage] = useState("");
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [currentStep, setCurrentStep] = useState(2);

  const navigate = useNavigate();

  const backgroundStyle = {
    width: isMobile ? "85vw" : "65vw",
    height: isMobile ? "70vh" : "60vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: isMobile ? "20%" : "50%",
    transform: isMobile ? "translate(10%, 20%)" : "translate(30%, 30%)",
    display: "grid",
    gridTemplateColumns: "30% 70%",
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: isMobile ? "4rem" : "2rem",
    margin: 0,
    marginLeft: "10%",
    marginTop: isMobile ? "12%" : "6%",
    bottom: 0,
  };

  const subtitleStyle = {
    marginLeft: "10%",
    fontSize: isMobile ? "2rem" : "1rem",
    color: "#868080",
    fontWeight: "500",
    top: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${config.API_URL}/default/templates`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (result.status === 200) {
        setMessage(
          insertName(result.message, landlordInformation.name ?? contactName)
        );
      }
      setTemplateMessages(result.data);
    };
    // fetchData();
  }, [message, index]);

  const getMessage = async () => {
    console.log(
      `mappedReason: ${mapReason(reason)[user?.language.substring(0, 2)]}`
    );
    try {
      const messageBody = await createMessage({
        language: user?.language.substring(0, 2),
        landlord_name: landlordInformation?.name ?? contactName,
        landlord_email: landlordInformation?.email ?? contactEmail,
        landlord_address: landlordInformation?.address ?? contactAddress,
        reason: mapReason(reason)[user?.language.substring(0, 2)],
        compensation_request: mapReason(reason).compensation_estimate,
        tenant_name: user?.fullName,
      });

      const greeting =
        user?.language.substring(0, 2) === "de"
          ? contactAddress === "mrs"
            ? "Sehr geehrte Frau"
            : "Sehr geehrter Herr"
          : contactAddress === "mr"
            ? "Dear Mr."
            : "Dear Mrs";

      const message = `${greeting} ${contactName},\n\n${messageBody.replace(RegExp("<br><br>", "g"), " ")}\n\n`; // quick'n'dirty fix for line breaks
      setTemplateMessages([message]);
      setMessage(messageBody.replace(RegExp("<br><br>", "g"), " "));
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  const handleContinueClicked = async () => {
    if (!contactEmail) {
      setResponse(t("cases-send.name"));
    } else if (!contactEmail) {
      setResponse(t("cases-send.email"));
    } else if (validateEmail()) {
      setContinueClicked(true);
      console.log("continue clicked: " + continueClicked);
    } else {
      setResponse(t("cases-send.invalid-email"));
    }

    setCurrentStep((prevStep) => {
      const newStep = prevStep + 1;
      return newStep;
    });

    await getMessage();
  };

  const validateEmail = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactEmail);

  const handleIndex = () => {
    setMessage(templateMessages[index]);
  };

  useAnalytics();

  const sendMessage = async () => {
    console.log("sending message");
    let result;

    try {
      result = await axios.post(
        `${config.API_URL}/default/correspondence/${caseId}`,
        {
          type: "landlord",
          caseId,
          message,
          reason,
          tenantId: user?.tenantId,
          recipientName: landlordInformation?.name ?? contactName,
          recipientEmail: landlordInformation?.email ?? contactEmail,
          recipientAddress: landlordInformation?.address ?? contactAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setDialogSuccess(true);
      setDialogTitle(t("cases-send.sent"));
      setDialogMessage(t("cases-send.inform"));
      //setResponse(t("cases-send.sent") + t("cases-send.inform"));
      setCurrentStep(4);
      setTimeout(() => navigate("/cases"), 5000);
    } catch (error) {
      setDialogError(true);
      setDialogTitle(t("cases-send.error"));
      setResponse(t("cases-send.error"));
    }
  };

  const handleAddressChange = (event) => {
    setContactAddress(event.target.value);
  };

  const handleEmailChange = (event) => {
    // BETA: add correct email back setContactEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setContactName(event.target.value);
  };

  return (
    <div style={{ position: "absolute" }}>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={backgroundStyle}>
        <div
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "30px 0px 0px 30px",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ marginLeft: "5%", marginTop: "20%" }}>
            <Step step={1} currentStep={currentStep}></Step>
            <Step step={2} currentStep={currentStep}></Step>
            <Step step={3} currentStep={currentStep}></Step>
            <Step step={4} currentStep={currentStep}></Step>
          </div>
        </div>
        <div>
          <Container
            style={{
              width: "100%",
              margin: 0,
              rowGap: 0,
              columnGap: 0,
              marginTop: "2%",
            }}
            itemWidth="auto"
            gap="0"
            columnWidths={["80%", "20%"]}
            alignItems="auto"
            itemsPerRow={2}
          >
            <p style={titleStyle}>{t("cases-send.send")}</p>

            <CancelButton
              style={{ marginTop: isMobile ? "35%" : "20%" }}
              onClick={() => onCancel()}
            ></CancelButton>
            {currentStep !== 4 ? (
              <p style={subtitleStyle}>{t("cases-send.choose")}</p>
            ) : undefined}
          </Container>

          {currentStep === 2 ? (
            <div
              style={{
                marginLeft: "8%",
                fontSize: isMobile ? "2rem" : "",
                marginTop: "3%",
              }}
            >
              <p style={{ fontSize: isMobile ? "1.8rem" : "" }}>
                {t("cases-send.landlord-name")}
              </p>
              <Container
                style={{ width: isMobile ? "90%" : "70%" }}
                itemsPerRow={2}
                itemWidth="auto"
                columnWidths={["20%", "75%"]}
              >
                <SelectionInput
                  onChange={(event) => handleAddressChange(event)}
                  options={[
                    { label: t("cases-send.title1"), value: "ms" },
                    { label: t("cases-send.title2"), value: "mr" },
                    { label: t("cases-send.other"), value: "other" },
                  ]}
                ></SelectionInput>
                <Input
                  onChange={(event) => handleNameChange(event)}
                  onEnter={() => {}}
                  placeholder="Maximilian Mustermann"
                  value={contactName}
                ></Input>
              </Container>

              <p
                style={{
                  fontSize: isMobile ? "1.8rem" : "",
                  marginTop: isMobile ? "5%" : "3%",
                }}
              >
                {t("cases-send.landlord-email")}
              </p>
              <Input
                type="email"
                onChange={(event) => handleEmailChange(event)}
                onEnter={() => {}}
                style={{ width: isMobile ? "90%" : "70%" }}
                value={
                  user?.beta ? "vermieter@mails.smartmieter.com" : contactEmail
                }
                placeholder="mustermann@gmail.com"
              ></Input>
            </div>
          ) : currentStep === 3 ? (
            <p
              style={{
                marginLeft: "8%",
                width: "80%",
                fontSize: "0.7rem",
                fontStyle: "italic",
                whiteSpace: "pre-line",
              }}
            >
              {templateMessages[0]}
            </p>
          ) : (
            <p style={{ width: "60%", marginLeft: "8%" }}>
              {t("cases-send.confirmation")}
            </p>
          )}

          <p
            style={{
              marginLeft: "5%",
              position: "absolute",
              bottom: "13%",
              fontSize: isMobile ? "1.4rem" : "0.9rem",
              width: "80%",
            }}
          >
            {response}
          </p>

          {currentStep !== 4 ? (
            <Button
              style={{
                width: isMobile ? "50%" : "30%",
                height: isMobile ? "7rem" : "3.5rem",
                marginLeft: "5%",
                borderRadius: "100px",
                bottom: "5%",
                position: "absolute",
              }}
              onClick={() =>
                landlordInformation
                  ? sendMessage()
                  : continueClicked
                    ? sendMessage()
                    : handleContinueClicked()
              }
              label={
                continueClicked
                  ? t("cases-send.send-message")
                  : t("cases-send.continue")
              }
            ></Button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
