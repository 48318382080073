import React from "react";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { EasyHelp } from "./components/easyhelp";
import { Partners } from "./components/partners";
import { How } from "./components/how";
import { WaitList } from "./components/waitlist";
import { HeroSection } from "./components/hero";
import { useRef, useState } from "react";
import { ServiceCharge } from "./components/servicecharge";
import { storeAnalyticsEvent, useAnalytics } from "../../analytics";
import ReactGA from "react-ga4";
import { Numbers } from "./components/numbers";
import { Problems } from "./components/problems";
import { EmailInput } from "./components/emailinput";
import { DarkBlurredBackground } from "../../shared/components";
import { Account } from "../../Account";

ReactGA.initialize("G-7ZVT49R3P1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const Home = () => {
  const parentRef = useRef();
  const nestedRef = useRef(null);
  const [emailVisible, setEmailVisible] = useState(false);
  const [useEmailFunnel, setUseEmailFunnel] = useState(true);
  const [waitlistFeature, setWaitlistFeature] = useState(false);

  useAnalytics();

  const handleHighRentClicked = (feature) => {
    setUseEmailFunnel(false);
    setWaitlistFeature(feature);
    setEmailVisible(true);

    storeAnalyticsEvent({
      category: "/home:servicecharges",
      action: "clicked",
      label: "High Rent Clicked",
    });
  };

  return (
    <div
      ref={parentRef}
      style={{
        top: 0,
        margin: 0,
        padding: 0,
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header parentRef={parentRef} nestedRef={nestedRef}></Header>
      <HeroSection ref={nestedRef} />
      <Problems onClick={() => setEmailVisible(true)}></Problems>
      <Numbers onClick={() => setEmailVisible(true)}></Numbers>
      <ServiceCharge
        highRentClicked={() => handleHighRentClicked("highRent")}
        highServiceChargeClicked={() => setEmailVisible(true)}
      ></ServiceCharge>
      <EasyHelp></EasyHelp>
      <Partners onClick={() => setEmailVisible(true)}></Partners>
      <How></How>
      <WaitList></WaitList>
      {emailVisible ? (
        <DarkBlurredBackground></DarkBlurredBackground>
      ) : undefined}
      {emailVisible ? (
        <EmailInput
          feature={waitlistFeature}
          funnel={useEmailFunnel}
          type={"waitlist"}
          onClose={() => setEmailVisible(false)}
        ></EmailInput>
      ) : undefined}
      {/*<Plans></Plans>*/}
      {/*<FAQ></FAQ>*/}
      <Footer></Footer>
      <Account></Account>
    </div>
  );
};

export default Home;
