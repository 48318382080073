import { useRef, useState } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
} from "../shared/components.js";
import { AuthError, HomeButton } from "./components.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./provider.jsx";
import { useTranslation } from "react-i18next";
import { storeAnalyticsEvent, useAnalytics } from "../analytics.js";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile.js";

const Auth = () => {
  const isMobile = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [readyForSignIn, setReadyForSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");

  const emailRef = useRef();
  const passwordRef = useRef();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useAnalytics();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const continueClicked = () => {
    if (!email) {
      setAuthError(true);
      setError("emailMissing");
      return;
    }

    if (!emailRegex.test(email)) {
      setAuthError(true);
      setError("invalidEmail");
      return;
    }

    setAuthError(false);
    setItems((prevItems) => [...prevItems, `Item ${prevItems.length + 1}`]);
    setReadyForSignIn(true);
  };

  const handleSignIn = async () => {
    switch (true) {
      case !email.trim():
        setAuthError(true);
        setError("emailMissing");
        return;
      case !password.trim():
        setAuthError(true);
        setError("passwordMissing");
        return;
      case !email.trim() && !password.trim():
        setAuthError(true);
        setError("inputMissing");
        return;
    }

    setAuthError(false);

    try {
      const authResult = await signIn(email, password);
      if (authResult.redirect) {
        navigate(authResult.redirect);
      }
    } catch (error) {
      setAuthError(error.authError);
      setError(error.type);
    }
  };

  return (
    <Container
      gap="0"
      margin="0"
      itemsPerRow={isMobile ? 1 : 2}
      alignItems="auto"
      itemWidth="auto"
      columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <div>
        <HomeButton />
        <Container
          style={{
            width: isMobile ? "80%" : "30vw",
            marginLeft: isMobile ? "10%" : "20%",
            marginTop: "20%",
          }}
          itemsPerRow={1}
          itemWidth="auto"
          margin="0"
        >
          <h1 style={{ fontSize: isMobile ? "4rem" : "2rem" }}>Login</h1>
          <h2 style={{ fontSize: isMobile ? "3rem" : "1.5rem" }}>
            {t("auth-login.email-question")}
          </h2>
          <Input
            ref={emailRef}
            style={{
              height: isMobile ? "6.5rem" : "3rem",
              borderRadius: isMobile ? "20px" : "7px",
              paddingLeft: isMobile ? "20px" : "10px",
              marginTop: isMobile ? "3%" : "1%",
              border: "1px solid lightgray",
            }}
            onChange={handleEmailChange}
            placeholder={t("auth-login.email-prompt")}
            type="email"
            onEnter={() => passwordRef.current?.focus()}
          ></Input>
          {items.map((item, index) => (
            <Input
              ref={passwordRef}
              onChange={handlePasswordChange}
              style={{
                height: isMobile ? "6.5rem" : "3rem",
                borderRadius: isMobile ? "20px" : "7px",
                paddingLeft: isMobile ? "20px" : "10px",
                marginTop: isMobile ? "3%" : "1%",
                border: "1px solid lightgray",
              }}
              type="password"
              placeholder={t("auth-login.password")}
              onEnter={readyForSignIn ? handleSignIn : continueClicked}
            ></Input>
          ))}
          <Button
            onClick={readyForSignIn ? handleSignIn : continueClicked}
            style={{
              height: isMobile ? "6.5rem" : "3rem",
              borderRadius: isMobile ? "20px" : "7px",
              marginTop: isMobile ? "3%" : "1%",
            }}
            label={
              readyForSignIn ? t("auth-login.login") : t("auth-login.continue")
            }
          ></Button>
          <AuthError visibility={authState} type={authError}></AuthError>
          {/*<h1 style={{ textAlign: "center" }}>Social Login</h1>*/}
          <HyperLink
            onClick={() =>
              storeAnalyticsEvent({
                category: "/login",
                action: "clicked",
                label: "Register",
              })
            }
            // BETA: change back to /register
            href="/alpha"
          >
            {t("auth-login.register")}
          </HyperLink>
        </Container>
      </div>
      {isMobile ? undefined : (
        <div style={{ backgroundColor: "#69ADFF", height: "100vh" }}>
          <Container
            itemsPerRow={1}
            itemWidth="80%"
            margin="0"
            gap="0"
            style={{ margin: "0rem 0rem 0rem 5rem" }}
          >
            <h3
              style={{ color: "white", fontSize: "2rem", marginTop: "10rem" }}
            >
              {t("How to assert your")}{" "}
              <span style={{ color: "black" }}>{t("Rights")} </span>
              {t("in four steps")}
            </h3>
            <Container
              itemsPerRow={2}
              style={{
                backgroundColor: "#EFEFEF",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <h3
                style={{
                  fontSize: "1.8rem",
                  fontWeight: 400,
                  marginLeft: "1.5rem",
                }}
              >
                1
              </h3>
              <p style={{ justifyContent: "center" }}>
                {t("Receive a free consultation about your possibilities.")}
              </p>
            </Container>
            <Container
              itemsPerRow={2}
              style={{
                backgroundColor: "#EFEFEF",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <h3
                style={{
                  fontSize: "1.8rem",
                  fontWeight: 400,
                  marginLeft: "1.5rem",
                }}
              >
                2
              </h3>
              <p style={{ justifyContent: "center" }}>
                {t(
                  "Access quick and around the clock help at your convenience"
                )}
              </p>
            </Container>
            <Container
              itemsPerRow={2}
              style={{
                backgroundColor: "#EFEFEF",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <h3
                style={{
                  fontSize: "1.8rem",
                  fontWeight: 400,
                  marginLeft: "1.5rem",
                }}
              >
                3
              </h3>
              <p style={{ justifyContent: "center" }}>
                {t(
                  "We'll put you in contact with specialized Attorneys in our Network"
                )}
              </p>
            </Container>
            <Container
              itemsPerRow={2}
              style={{
                backgroundColor: "#EFEFEF",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <h3
                style={{
                  fontSize: "1.8rem",
                  fontWeight: 400,
                  marginLeft: "1.5rem",
                }}
              >
                4
              </h3>
              <p style={{ justifyContent: "center" }}>
                {t("You receive the rights you are entitled to.")}
              </p>
            </Container>
          </Container>
        </div>
      )}
    </Container>
  );
};
export default Auth;
