/**Verify Account */
import { useTranslation } from "react-i18next";
import { getStageConfig } from "../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Header } from "../shared/header";
import axios from "axios";
import { useResponsiveStyles } from "../shared/mobile";

export const Confirm = ({}) => {
  const config = getStageConfig();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const isMobile = useResponsiveStyles();

  const [queryParams] = useSearchParams();
  console.log(`queryParams: ${queryParams}`);
  const [status, setStatus] = useState("");

  console.log(`email: ${decodeURIComponent(queryParams.get("user_name"))}`);

  useEffect(() => {
    const confirmUser = async () => {
      try {
        const result = await axios.post(
          `${config.API_URL}/default/user/${decodeURIComponent(queryParams.get("user_name"))}/confirm/${queryParams.get("verification_code")}`
        );

        if (result.status === 200) {
          setTimeout(() => {
            navigate("/app");
          }, 5000);
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error(error);
      }
    };

    confirmUser();
  });

  return (
    <div>
      <Header staticColor={true}></Header>
      <h1
        style={{
          marginTop: isMobile ? "20rem" : "10rem",
          fontSize: isMobile ? "4rem" : "2rem",
          marginLeft: "3%",
        }}
      >
        {status === "success"
          ? t("auth-confirm.success")
          : status === "error"
            ? t("auth-confirm.error")
            : "Verifying..."}
      </h1>
    </div>
  );
};
