import { useEffect, useState } from "react";
import { DarkBlurredBackground, SectionHead } from "../shared/components";
import axios from "axios";
import { getStageConfig } from "../config/config";
import { useAuth } from "../Auth/provider";
import { Container } from "../shared/components";
import { getDateByISO, mapReason, mapStatus } from "../shared/helpers";
import { Header } from "../shared/header";
import { CasesMenu } from ".";
import { useAnalytics } from "../analytics";
import { Correspondence } from "./correspondence";
import { AcceptSuggestion } from "./acceptsuggestion";
import { useResponsiveStyles } from "../shared/mobile";
import { Account } from "../Account";

export const CasesOverview = ({}) => {
  const isMobile = useResponsiveStyles();
  const { user, jwtToken } = useAuth();
  const config = getStageConfig();

  const [menuVisible, setMenuVisible] = useState(false);
  const [correspondenceVisible, setCorrespondenceVisible] = useState(false);
  const [acceptSuggestionSelected, setAcceptSuggestionSelected] =
    useState(false);
  const [Case, setCase] = useState();
  const [cases, setCases] = useState([]);

  useAnalytics();

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching cases");
      try {
        const cases = await axios.get(
          `${config.API_URL}/default/cases/${user?.tenantId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setCases(cases.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleCardClick = (index) => {
    setCase(cases[index]);
    setMenuVisible(true);
  };

  const handleShowCorrespondence = (visibile) => {
    setMenuVisible(false);
    setCorrespondenceVisible(visibile);
  };

  const handleShowAcceptSuggestion = (status) => {
    setAcceptSuggestionSelected(status);
    setCorrespondenceVisible(!status);
  };

  return (
    <div>
      <Header staticColor={true}></Header>
      <br></br>
      <SectionHead label="Cases"></SectionHead>

      {acceptSuggestionSelected ? (
        <AcceptSuggestion
          onCancel={() => handleShowAcceptSuggestion(false)}
        ></AcceptSuggestion>
      ) : undefined}
      <Container
        itemWidth="auto"
        itemsPerRow={isMobile ? 1 : 2}
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        style={{ width: "80%", marginLeft: "3%", marginBottom: "5%" }}
      >
        {cases
          ? cases
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((c, i) => (
                <CaseOverViewCard
                  onClick={() => handleCardClick(i)}
                  Case={c}
                ></CaseOverViewCard>
              ))
          : undefined}
      </Container>
      {menuVisible ? (
        <div>
          <DarkBlurredBackground></DarkBlurredBackground>
          <CasesMenu
            Case={Case}
            onClick={() => handleShowCorrespondence(true)}
            onCancel={() => setMenuVisible(false)}
          ></CasesMenu>
        </div>
      ) : undefined}
      {correspondenceVisible ? (
        <Correspondence
          Case={Case}
          onCancel={() => handleShowCorrespondence(false)}
          onAcceptSuggestion={() => handleShowAcceptSuggestion(true)}
        ></Correspondence>
      ) : undefined}
      <Account></Account>
    </div>
  );
};

export const CaseOverViewCard = ({ Case, onClick }) => {
  const isMobile = useResponsiveStyles();
  const { user } = useAuth();

  const cardStyle = {
    backgroundColor: "#ECEEEE",
    backgroundImage: `url(${require("./assets/background.png")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    border: "1px solid gray",
    position: "relative",
    borderRadius: isMobile ? "30px" : "15px",
    width: isMobile ? "55rem" : "32rem",
    height: isMobile ? "30rem" : "15rem",
    marginTop: "5%",
    cursor: "pointer",
  };

  const mappedStatus = mapStatus(Case.status)
    ? mapStatus(Case.status)[user?.language.substring(0, 2)]
    : "Unknown status";

  const clickHandler = () => {
    onClick();
  };

  return (
    <div style={cardStyle} onClick={() => clickHandler()}>
      <Container
        gap="0"
        margin="0"
        itemWidth="auto"
        columnWidths={isMobile ? ["60%", "40%"] : ["100%"]}
        itemsPerRow={2}
        style={{ marginLeft: "5%", bottom: 0 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100px",
            marginTop: isMobile ? "1%" : "",
            background: "linear-gradient(135deg, #69ADFF 0%, #B3E5FC 100%)",
            backgroundColor: "#69ADFF",
            height: isMobile ? "4.5rem" : "2.5rem",
            right: "3%",
            top: "3%",
            minWidth: "40%",
            position: "absolute",
          }}
        >
          <p
            style={{
              margin: 0,
              fontWeight: 600,
              whiteSpace: "nowrap",
              fontSize: isMobile ? "2rem" : "",
            }}
          >
            {mappedStatus}
          </p>
        </div>
      </Container>
      <p
        style={{
          position: "absolute",
          marginLeft: "5%",
          bottom: "20%",
          fontSize: isMobile ? "2rem" : "0.9rem",
        }}
      >
        {getDateByISO(Case.createdAt)}
      </p>
      <h1
        style={{
          position: "absolute",
          marginLeft: "5%",
          bottom: "5%",
          fontSize: isMobile ? "3rem" : "1.5rem",
        }}
      >
        {mapReason(Case.reason)[user?.language.substring(0, 2) ?? "de"]}
      </h1>
      <p
        style={{
          marginLeft: "5%",
          fontSize: isMobile ? "2.2rem" : "1.1rem",
          top: 0,
          width: "80%",
        }}
      >
        {Case.description}
      </p>
    </div>
  );
};
