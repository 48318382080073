import { useNavigate } from "react-router-dom";
import { Container, HyperLink, Button } from "./components";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "./mobile";

export const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const handleGetHelp = () => {
    // after BETA: /register
    navigate("/alpha");
  };

  const styles = {
    footer: {
      backgroundColor: "#16171D",
      color: "#FFFFFF",
      width: "100%",
      height: isMobile ? "85vh" : "70vh",
      display: "grid",
      alignItems: "center",
      position: "relative",
      bottom: 0,
    },
    content: {
      textAlign: "center",
    },
    text: {
      fontSize: "14px",
      margin: 0,
    },
  };

  return (
    <footer style={styles.footer}>
      <Container
        itemWidth="inherit"
        style={{
          marginLeft: "5%",
          marginTop: "5%",
        }}
      >
        <p
          style={{
            fontWeight: 900,
            color: "868080",
            fontSize: isMobile ? "2.5rem" : "1.2rem",
            textAlign: "left",
            marginBottom: "0",
            width: isMobile ? "80%" : "inherit",
          }}
        >
          {t("shared-footer.title1")}
        </p>
        <p
          style={{
            fontWeight: 900,
            fontSize: isMobile ? "5rem" : "2.5rem",
            textAlign: "left",
            marginTop: "0",
          }}
        >
          {t("shared-footer.title2")}
        </p>
        <Button
          onClick={() => handleGetHelp()}
          style={{
            width: isMobile ? "35rem" : "15rem",
            height: isMobile ? "7rem" : "3rem",
            borderRadius: "10rem",
            float: "left",
            marginTop: "0",
          }}
          label={t("shared-footer.text1")}
        ></Button>
      </Container>

      <h3
        style={{
          fontSize: isMobile ? "3rem" : "1.5rem",
          textAlign: "left",
          marginLeft: "5%",
        }}
      >
        smartmieter
      </h3>
      <Container
        itemsPerRow={3}
        alignItems="auto"
        itemWidth="auto"
        gap="0"
        margin="0"
        style={{ width: isMobile ? "90%" : "70%", marginLeft: "5%" }}
      >
        <Container
          gap="10px"
          margin="0"
          itemWidth="auto"
          style={{ marginLeft: "0" }}
        >
          <h2
            style={{
              fontSize: isMobile ? "2.5rem" : "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: 0,
            }}
          >
            Company
          </h2>
          <HyperLink
            onClick={() => {}}
            href={"/about"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
            }}
          >
            {t("shared-footer.text2")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/terms"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
              marginTop: "2%",
            }}
          >
            {t("shared-footer.text3")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/privacy"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
              marginTop: "2%",
            }}
          >
            {t("shared-footer.text4")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/landlords"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
              marginTop: "2%",
            }}
          >
            {t("shared-footer.text5")}
          </HyperLink>
        </Container>
        <Container gap="10px" margin="0" style={{ marginLeft: "20%" }}>
          <h2
            style={{
              fontSize: isMobile ? "2.5rem" : "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: 0,
            }}
          >
            {t("shared-footer.text6")}
          </h2>
          <HyperLink
            onClick={() => {}}
            href={"/contact"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
            }}
          >
            {t("shared-footer.text7")}
          </HyperLink>
          {
            <HyperLink
              onClick={() => {}}
              href={"/contact"}
              style={{
                color: "white",
                textDecoration: "none",
                textAlign: "left",
                float: "left",
                fontWeight: isMobile ? "400" : "600",
                marginTop: "2%",
              }}
            >
              {t("shared-footer.text8")}
            </HyperLink>
          }
        </Container>
        {/*<Container style={{ marginLeft: "20%" }}>
          <h2
            style={{
              fontSize: "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: "0",
            }}
          >
            Abos
          </h2>
          <HyperLink
            href={
              "/subscriptions?type=standard"
            } 
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
            }}
          >
            Standard
          </HyperLink>
          <HyperLink
            href={
              "/subscriptions?type=premium"
            } 
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
          >
            Premium
          </HyperLink>
        </Container>*/}
        <Container style={{ marginLeft: "20%" }}>
          <h2
            style={{
              fontSize: isMobile ? "2.5rem" : "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: 0,
            }}
          >
            Socials
          </h2>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.instagram.com/getsmartmieter/"
            } /** add instagram url here */
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
            }}
            externalLink={true}
          >
            Instagram
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.linkedin.com/company/smart-mieter/about/?viewAsMember=true"
            }
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
              marginTop: "2%",
            }}
            externalLink={true}
          >
            LinkedIn
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"https://x.com/smart_mieter"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: isMobile ? "400" : "600",
              marginTop: "2%",
            }}
            externalLink={true}
          >
            X/Twitter
          </HyperLink>
        </Container>
        <br></br> <br></br>
      </Container>
    </footer>
  );
};
