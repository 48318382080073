import { Pill } from "../../../App/components";
import { Container } from "../../../shared/components";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../../shared/mobile";

export const Problems = ({ onClick }) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const iGot99ProblemsAndABitchAintOne = [
    t("home.comp-problems.prob1"),
    t("home.comp-problems.prob2"),
    t("home.comp-problems.prob3"),
    t("home.comp-problems.prob4"),
    t("home.comp-problems.prob5"),
    t("home.comp-problems.prob6"),
    t("home.comp-problems.prob7"),
  ];

  return (
    <div
      style={{
        height: isMobile ? "auto" : "60vh" /*50vh*/,
        position: "relative",
        marginTop: "15%",
        marginBottom: "35vh",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: isMobile ? "5rem" : "3rem",
          bottom: 0,
        }}
      >
        {t("You know your problem. We know the solution.")}
      </h1>
      <h2
        style={{
          textAlign: "center",
          fontSize: isMobile ? "2.5rem" : "1.5rem",
        }}
      >
        {t(
          "Explain your problem and we'll look to solve your issue as fast as possible."
        )}
      </h2>
      <Container
        itemWidth="auto"
        itemsPerRow={4}
        columnWidths={["25%", "25%", "25%", "25%"]}
        gap="10px"
        style={{
          position: "absolute",
          width: "90%",
          left: "5%",
          right: "5%",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          margin: "2%",
          marginTop: "15%",
        }}
      >
        {iGot99ProblemsAndABitchAintOne.map((input, index) => (
          <Pill
            key={index}
            label={input}
            style={{ minWidth: "88%", marginLeft: "2%", marginRight: "2%" }}
            textStyle={{ fontSize: "0.8rem" }}
            onClick={onClick}
          />
        ))}
      </Container>
    </div>
  );
};
