import { storeAnalyticsEvent, useAnalytics } from "../../../analytics";
import { Container, Input } from "../../../shared/components";
import { forwardRef } from "react";
import axios from "axios";
import { useState } from "react";
import { getStageConfig } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const HeroSection = forwardRef(({ url }, ref) => {
  const config = getStageConfig();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isMobile = useResponsiveStyles();

  const { t, i18n } = useTranslation();
  const styles = {
    backgroundColor: "#69ADFF",
    height: isMobile ? "140vw" : "100vh",
    width: "100%",
    marginBottom: "5%",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "relative",
    overflowY: "hidden",
  };

  const noteStyle = {
    margin: 0,
    fontSize: isMobile ? "3rem" : "1.5rem",
    zIndex: 10,
    fontWeight: 700,
    position: "relative",
    marginLeft: "5%",
    marginTop: isMobile ? "40%" : "25%",
    maxWidth: isMobile ? "100%" : "70%",
  };

  const heroTitleStyle = {
    fontSize: isMobile ? "6rem" : "5rem",
    color: "white",
    zIndex: 10,
    fontWeight: 800,
    position: "relative",
    marginTop: "1%",
    bottom: 0,
    margin: 0,
    marginLeft: "5%",
  };

  const subtitleStyle = {
    color: "white",
    fontSize: isMobile ? "2.5rem" : "1.4rem",
    fontWeight: 500,
    position: "relative",
    zIndex: 10,
    marginLeft: "5%",
    marginTop: "1%",
    maxWidth: "70%",
  };

  const roomImageSrc = {
    mobile: require("../assets/smartmieter_room_mobile.png"),
    desktop: require("../assets/smartmieter_room.png"),
  };

  useAnalytics();

  const createLead = async () => {
    if (email.includes("@")) {
      try {
        await axios.post(`${config.API_URL}/default/leads/${email}`);

        localStorage.setItem("lead_email", email);
        // after BETA: navigate to /funnel
        navigate("/alpha", { state: { email } });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 409) {
          setError(t("home.comp-hero.email-exists"));
        }
      }
    }

    storeAnalyticsEvent({
      category: "/home:hero",
      action: "clicked",
      label: "Email Input",
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    storeAnalyticsEvent({
      category: "/home:hero",
      action: "typed",
      label: t("home.comp-hero.email-input"),
    });
  };

  const mobileImageStyle = {
    width: "100%",
    position: "absolute",
    marginTop: "-80%",
  };

  return (
    <section style={styles} ref={ref}>
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="100%"
        style={{ width: "90%" }}
        columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
      >
        <div>
          <p style={noteStyle}>{t("#1 in Rental Rights")}</p>
          <h1 style={heroTitleStyle}>{t("hero_headline")}</h1>
          <h2 style={subtitleStyle}>{t("home.comp-hero.compensated")}</h2>
          <Container
            style={{
              marginLeft: "5%",
              width: isMobile ? "90%" : "70%",
              marginTop: "4%",
            }}
            itemWidth="auto"
            itemsPerRow={isMobile ? 1 : 2}
            columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
          >
            <Input
              style={{
                height: isMobile ? "7rem" : "3.2rem",
                borderRadius: "100px",
                paddingLeft: "30px",
                border: "none",
                position: "relative",
                fontWeight: 600,
                zIndex: 10,
              }}
              onEnter={createLead}
              onChange={(e) => handleEmailChange(e)}
              placeholder="name@example.com"
            ></Input>
            <button
              onClick={createLead}
              style={{
                backgroundColor: "black",
                color: "white",
                width: isMobile ? "25rem" : "10rem",
                borderRadius: "100px",
                textAlign: "center",
                height: isMobile ? "8rem" : "3.2rem",
                fontSize: isMobile ? "3.5rem" : "1.3rem",
                marginTop: isMobile ? "1rem" : "",
                fontWeight: 600,
                zIndex: 10,
                position: "relative",
                border: "none",
                marginLeft: isMobile ? "0" : "5%",
                cursor: "pointer",
              }}
            >
              {t("home.comp-hero.start")}
            </button>
          </Container>
          <p style={{ marginLeft: "5%", color: "white" }}>{error}</p>
        </div>
        <img
          style={
            isMobile ? mobileImageStyle : { width: "110%", marginTop: "60%" }
          }
          src={isMobile ? roomImageSrc.mobile : roomImageSrc.desktop}
        ></img>
        {isMobile ? undefined : (
          <Container
            style={{
              width: "90%",
              marginLeft: "5%",
              bottom: "2%",
              position: "absolute",
            }}
            itemWidth="auto"
            columnWidths={["17%", "17%", "17%", "17%", "17%", "17%"]}
            itemsPerRow={6}
          >
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.rent")}
              </p>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.water")}
              </p>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.heating")}
              </p>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.service-charges")}
              </p>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.mold")}
              </p>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                style={{ width: "90%" }}
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <p style={{ fontWeight: 600, color: "white" }}>
                {t("home.comp-hero.more")}
                <br></br> {t("home.comp-hero.30")}
              </p>
            </Container>
          </Container>
        )}
      </Container>
    </section>
  );
});
