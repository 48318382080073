import { Button, CancelButton, Input } from "../../../shared/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getStageConfig } from "../../../config/config";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const EmailInput = ({
  funnel = true,
  type = "funnel",
  feature,
  onClose,
}) => {
  const isMobile = useResponsiveStyles();
  const config = getStageConfig();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const styles = {
    backgroundColor: "white",
    height: isMobile ? "60%" : "70vh",
    width: isMobile ? "80%" : "70vh",
    position: "fixed",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  };

  const subtitle = {
    funnel: t("home.comp-email.problem"),
    waitlist: t("home.comp-email.update"),
  }[type];

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const createLead = async () => {
    if (email.includes("@")) {
      try {
        await axios.post(`${config.API_URL}/default/leads/${email}`);
      } catch (error) {
        console.error(error);
      }
      localStorage.setItem("lead_email", email);
      navigate("/funnel");
    } else {
      setStatus("home.comp-email.valid-email");
    }
  };

  const addWaitlist = async () => {
    let result;
    try {
      result = await axios.post(
        `${config.API_URL}/default/leads/${email}/waitlist?feature=${feature}`
      );
    } catch (error) {
      console.error(error);
    }

    if (result && result.status === 201) {
      setStatus(t("home.comp-email.success"));
      setTimeout(onClose, 3000);
    } else {
      setStatus(t("home.comp-email.error"));
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div style={styles}>
      <CancelButton
        style={{ position: "absolute", right: "2%", top: "2%" }}
        onClick={onClose}
      ></CancelButton>
      <p
        style={{
          margin: 0,
          marginLeft: "10%",
          marginTop: "10%",
          width: "80%",
          fontSize: isMobile ? "4rem" : "2rem",
          fontWeight: 600,
          bottom: 0,
        }}
      >
        {t("home.comp-email.email1")}
      </p>
      <p
        style={{
          marginLeft: "10%",
          fontSize: isMobile ? "2.6rem" : "1.3rem",
          fontWeight: 400,
          top: 0,
        }}
      >
        {subtitle}
      </p>
      <Input
        style={{
          marginLeft: "10%",
          width: "80%",
          marginTop: "10%",
          borderRadius: "20px",
          height: isMobile ? "6rem" : "3.5rem",
        }}
        onChange={(event) => handleEmailChange(event)}
        onEnter={funnel ? createLead : addWaitlist}
        placeholder={t("home.comp-email.email2")}
      ></Input>
      <p style={{ marginLeft: "10%" }}>{status}</p>
      <Button
        style={{
          marginLeft: "10%",
          width: "80%",
          marginTop: "2%",
          borderRadius: "100px",
          height: isMobile ? "6rem" : "3.5rem",
        }}
        label={funnel ? t("home.comp-email.check") : t("home.comp-email.inform")}
        onClick={funnel ? createLead : addWaitlist}
      ></Button>
    </div>
  );
};
